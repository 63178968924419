import moment from "moment";

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let years = today.getFullYear() - dob.getFullYear();
    let months = today.getMonth() - dob.getMonth();
    let days = today.getDate() - dob.getDate();

    // Adjust the months and years if the birthday hasn't occurred yet this year
    if (months < 0 || (months === 0 && days < 0)) {
        years--;
        months += 12;
    }

    // Adjust the days if the day of the month hasn't occurred yet
    if (days < 0) {
        const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        days += lastMonth.getDate();
        months--;
    }

    return `${years ? years : ""} ${years ? "year" : ""}${years > 1 ? 's' : ''} ${months > 0 ? months : ""} ${months > 1 ? 'months' : months == 1 ?'month' : ""} ${months == 0 && days > 1 ? days : ""} ${months == 0 && days > 1  ? 'days' : days == 1 ? 'day' : ""}`;
}

export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}

export function setDateWithTime(dateString, timeString, addMinutes = 0) {
    const dateStringFormatted = dateString.split("T")?.[0]  ? dateString.split("T")?.[0] :  dateString || "";
    // Parse the date string
    const date = moment(dateStringFormatted);

    // Parse the time string
    const [hours, minutes] = timeString.split(':').map(Number);

    // Set the time
    date.set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0
    });

    // Add extra minutes if provided
    if (addMinutes) {
        date.add(addMinutes, 'minutes');
    }

    // Return the updated date
    return moment(date).toDate();
}

export function convertTo12HourFormat(time24) {

    if(!time24){
        return "";
    }
    // Split the input string into hours and minutes
    let [hours, minutes] = time24?.split(':').map(Number);
    
    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight and 12-hour clock conversion
    
    // Format the hours and minutes with leading zeros if necessary
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    
    // Return the formatted time
    return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function capitalizeEveryWord(sentence) {
    if (!sentence) return ""; // Handle empty or invalid input
    return sentence
        .split(" ") // Split the sentence into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a sentence
}