import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { CardTitle, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { IconSVG } from "components/Common/IconSvg"
import moment from "moment"

const AppointmentDetailsCol = ({ testDetails }) => {
  console.log("testDetails", testDetails)
  return (
    <ul className="list-unstyled parent-details__container">
      <div className="d-flex justify-content-between">
        <CardTitle className="mb-4">Contact Info</CardTitle>
        <div className="history-doc-cta">
          <Link to={`/edit-appointment/${testDetails._id}`}>
            Edit <IconSVG icon="edit" />
          </Link>
        </div>
      </div>
      <Row>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title">Patient Name</h6>
            <p className="parent-details__value">
              {testDetails?.petObjectId?.fullName}
            </p>
          </li>
          <li>
            <h6 className="parent-details__title">Pet Type</h6>
            <p className="parent-details__value">{testDetails?.petType}</p>
          </li>
          <li>
            <h6 className="parent-details__title">Pet Breed</h6>
            <p className="parent-details__value">
              {testDetails?.petObjectId?.petBreed}
            </p>
          </li>
        </Col>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title">Date</h6>
            <p className="parent-details__value">
              {moment(testDetails?.date).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title">Appointment Time</h6>
            <p className="parent-details__value">{testDetails?.time}</p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title">Appointment Type</h6>
            <p className="parent-details__value">{testDetails?.visitType}</p>
          </li>
        </Col>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title">Fee</h6>
            <p className="parent-details__value">₹{testDetails?.cost}</p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title">Appointment Status</h6>
            <p className="parent-details__value">
              {testDetails?.appointmentStatus}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title">Notes</h6>
            <p className="parent-details__value">{testDetails?.notes}</p>
          </li>
        </Col>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title">Pet Parent</h6>
            <p className="parent-details__value">
              {testDetails?.parentId?.fullName}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title">Status</h6>
            <p className="parent-details__value">{testDetails?.status}</p>
          </li>
        </Col>
      </Row>
    </ul>
  )
}

AppointmentDetailsCol.propTypes = {
  details: PropTypes.any,
}

export default AppointmentDetailsCol
