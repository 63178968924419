import React from "react";
import "./Legend.scss";

const Legend = () => {
  return (
    <div className="legend">
      <div className="legend-item">
        <div className="legend-color completed"></div>
        <span>Completed</span>
        <span className="tooltip">Event is completed</span>
      </div>
      <div className="legend-item">
        <div className="legend-color scheduled"></div>
        <span>Scheduled</span>
        <span className="tooltip">Event is scheduled</span>
      </div>
      <div className="legend-item">
        <div className="legend-color cancelled"></div>
        <span>Cancelled</span>
        <span className="tooltip">Event is cancelled</span>
      </div>
      <div className="legend-item">
        <div className="legend-color checkIn"></div>
        <span>Check-In</span>
        <span className="tooltip">{"Event checked-in"}</span>
      </div>
      <div className="legend-item">
        <div className="legend-color past"></div>
        <span>Past Appointments</span>
        <span className="tooltip">{"Past appointments"}</span>
      </div>
    </div>
  );
};

export default Legend;
