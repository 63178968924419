import axios from "axios"
import { BASE } from "helpers/api_helper"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactSelect from "react-select"
import { Col, Form, Input, Label, Row } from "reactstrap"

const VaccinationForm = ({ onConfirm, data, agegroup, petType }) => {
  const [formRows, setFormRows] = useState(data || [{ id: 1 }])
  const [isLoading, setIsLoading] = useState(false)
  const [vaccinationData, setVaccinationData] = useState([])
  const [vaccineType, setVaccineType] = useState([])
  const [secondSelectOptions, setSecondSelectOptions] = useState([])
  const [inputValue, setInputValue] = useState()

  useEffect(() => {
    setFormRows(data || [{ id: 1 }])
  }, [data])

  // const onAddFormRow = () => {
  //   const modifiedRows = [...formRows]
  //   modifiedRows.push({ id: modifiedRows.length + 1 })
  //   setFormRows(modifiedRows)
  //   setSecondSelectOptions([...secondSelectOptions, []])
  // }

  const onAddFormRow = () => {
    // Check if all existing rows have the required fields filled
    const hasEmptyFields = formRows.some(
      row => !row.type || !row.vaccine || !row.DOV || !row.EOV
    )

    if (hasEmptyFields) {
      setInputValue("Please fill all fields before adding a new row.")
      return
    }

    // Add a new row
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
    setSecondSelectOptions([...secondSelectOptions, []]) // Add a new empty options array for the new row
    setInputValue(null) // Clear any previous validation message
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      const modifiedRows = formRows.filter(row => row.id !== id)
      const modifiedOptions = secondSelectOptions.filter(
        (_, index) => index !== id - 1
      )
      setFormRows(modifiedRows)
      setSecondSelectOptions(modifiedOptions)
      onConfirm(modifiedRows)
    }
  }

  const handleAddTitleAndDescription = (index, type, value) => {
    const modifiedRows = [...formRows]
    modifiedRows[index][type] = value
    setFormRows(modifiedRows)
    onConfirm(modifiedRows)
  }

  const handleChange = (selected, index) => {
    handleAddTitleAndDescription(index, "type", selected.value)
    fetchSecondSelectOptions(selected.value, index)
  }

  const fetchSecondSelectOptions = (type, index) => {
    const selectedType = vaccinationData.find(vaccine => vaccine.type === type)
    if (selectedType) {
      const options = selectedType.vaccines.map(vaccine => ({
        label: vaccine.title,
        value: vaccine.title,
      }))
      const modifiedOptions = [...secondSelectOptions]
      modifiedOptions[index] = options
      setSecondSelectOptions(modifiedOptions)
    }
  }

  const loadOptions = async () => {
    try {
      const response = await axios.get(
        `${BASE}api/vaccinations/v1/vaccinationList`
      )
      const data = response.data.data || []
      const options = data.map(item => ({
        label: item.type,
        value: item.type,
      }))
      setVaccinationData(data)
      setVaccineType(options)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <>
      <Col lg={12}>
        <div className="text-center">
          <br />
          <Form className="repeater mt-12" encType="multipart/form-data">
            <div>
              <Row>
                <Col lg={4}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Type
                  </Label>
                </Col>
                <Col lg={2}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Vaccines
                  </Label>
                </Col>
                <Col lg={3}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Date of Vaccine
                  </Label>
                </Col>
                <Col lg={2}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label  text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Expiry Date
                  </Label>
                </Col>
                <Col lg={1}></Col>
              </Row>
              {(formRows || []).map((formRow, key) => {
                return (
                  <Row key={key} className="mb-4">
                    <Col lg={3}>
                      <ReactSelect
                        value={vaccineType.find(
                          option => option.value === formRow.type
                        )}
                        getOptionLabel={e => `${e.label}`}
                        getOptionValue={e => e.value}
                        options={vaccineType}
                        onChange={selected => handleChange(selected, key)}
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                      />
                    </Col>
                    <Col lg={3}>
                      <ReactSelect
                        isLoading={isLoading}
                        isSearchable
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        placeholder="Select vaccines"
                        options={secondSelectOptions[key]}
                        value={formRow.vaccine}
                        onChange={selected =>
                          handleAddTitleAndDescription(key, "vaccine", selected)
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <Input
                        type="date"
                        placeholder="Date of vaccinations"
                        min={new Date().toISOString().split("T")[0]}
                        value={formRow.DOV || ""}
                        onChange={e =>
                          handleAddTitleAndDescription(
                            key,
                            "DOV",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col lg={2}>
                      <Input
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        value={formRow.EOV || ""}
                        onChange={e =>
                          handleAddTitleAndDescription(
                            key,
                            "EOV",
                            e.target.value
                          )
                        }
                      />
                    </Col>

                    <Col lg={1} className="align-self-center">
                      <div className="d-grid">
                        <button
                          type="button"
                          style={{ height: 47 }}
                          className="btn btn-dark"
                          onClick={() => onDeleteFormRow(formRow.id)}
                        >
                          <i className="bx bx-minus label-icon"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </div>
            <div
              className="justify-content-end d-flex"
              style={{ padding: 40, paddingRight: 0 }}
            >
              <button
                type="button"
                className="btn btn-dark"
                value="Add"
                onClick={onAddFormRow}
              >
                <i className="bx bx-plus label-icon"></i>
              </button>
            </div>
          </Form>
          {<p style={{ color: "red" }}>{inputValue}</p>}
        </div>
      </Col>
    </>
  )
}

VaccinationForm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.array,
  agegroup: PropTypes.string,
  petType: PropTypes.string,
}

export default VaccinationForm
