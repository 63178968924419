import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import EmptyTableContainer from "./EmptyTableContainer"
import ReactSelect from "react-select"
import { reasonOptions } from "helpers/utils"
import { useLocation, Link } from "react-router-dom"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  emptyListLink,
  emptyLinkText,
  emptyText,
  setReasonType,
  reasonType,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const location = useLocation()

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleVisitTypeChange = selectedOption => {
    setReasonType(selectedOption)
  }

  const handleVisitTypeMenuOpen = () => {
    // setTimeout(() => {
    //   if (filterPricingOptions.length === 0) {
    //     showMessage({
    //       label: "No Visit Type Available! Need to Create First",
    //       status: "info",
    //     })
    //   }
    // }, 1000)
  }

  return (
    <Fragment>

      <Row className="mb-2 d-flex flex-wrap align-items-center gap-3">
        <Col md="auto" sm="3" xs="12">
          <select
            className="form-select"
            value={pageSize}
            style={{ height: "49px", borderRadius: "8px", cursor: "pointer" }}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>

        {isGlobalFilter && (
          <Col md="auto" sm="auto" xs="12">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isJobListGlobalFilter={isJobListGlobalFilter}
            />
          </Col>
        )}

        {isAddOptions && (
          <Col md="auto" sm="auto" xs="12" className="text-end">
            <Button
              type="button"
              color="success"
              className="new-actions-cta"
              onClick={handleOrderClicks}
            >
              {emptyLinkText || (
                <>
                  <i className="mdi mdi-plus me-1" /> Add New Patient
                </>
              )}
            </Button>
            {location.pathname === "/pharmacy-request" && (
              <>
                <Button
                  type="button"
                  // color="success"
                  className=" border border-success p-2 rounded bg-white text-black me-2"
                  onClick={() => handleOrderClicks("/re-issue-pharmacy")}
                >
                  Re-Issue Pharmacy
                </Button>

                <Button
                  type="button"
                  // color="success"
                  className="border border-success p-2 rounded bg-white text-black"
                  onClick={() => handleOrderClicks("/dispense-pharmacy")}
                >
                  Dispense Pharmacy
                </Button>
              </>
            )}
          </Col>
        )}

        {location.pathname === "/appointments" && (
          <Col md="auto" sm="auto" xs="12" className="text-end">
            <ReactSelect
              value={reasonType}
              onChange={handleVisitTypeChange}
              options={reasonOptions}
              classNamePrefix="form-react-select"
              className="form-react-select"
              onMenuOpen={handleVisitTypeMenuOpen}
            />
          </Col>
        )}

        {isAddUserList && (
          <Col md="auto" sm="auto" xs="12" className="text-end">
            <Button
              type="button"
              color="primary"
              className="btn mb-2 me-2"
              onClick={handleUserClick}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Create New User
            </Button>
          </Col>
        )}

        {isAddCustList && (
          <Col md="auto" sm="auto" xs="12" className="text-end">
            <Button
              type="button"
              color="success"
              className="btn-rounded mb-2 me-2"
              onClick={handleCustomerClick}
            >
              <i className="mdi mdi-plus me-1" />
              New Customers
            </Button>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap ">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div
                      className="title font-fontFamily"
                      {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              console.log("row", row.cells[0].id)
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td
                          key={cell.id}
                          {...cell.getCellProps()}
                          style={{ paddingLeft: "17px", textWrap: "nowrap" }}
                          className="font-fontFamily"
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      {!page?.length && (
        <EmptyTableContainer
          emptyListLink={emptyListLink}
          emptyLinkText={emptyLinkText}
          emptyText={emptyText}
        />
      )}
      {location.pathname !== "/pricing-Category" && (
        <Row className="pagination-container justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
