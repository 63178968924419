import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "./components/Legend.scss";

import { Col, Container, Row, UncontrolledTooltip } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import TableContainer from "../../components/Common/TableContainer"
import DeleteModal from "./DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import {
  getTodaysAppointments,
  fetchAppointmentTypes,
  getUserDetails,
  getAppointmentsByDocId,
  getAppointmentsOfDoc,
  setGlobalLoading,
} from "store/slices"
import {
  BillingName,
  StatusName,
  Total,
} from "pages/Pets/components/PatientsCol"
import { cancleAppointment, deleteAppointment, updateAppointmentPaymentStatus } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
//calender buttons css
import "../../assets/scss/custom/components/_calender.scss"
import { Date, Parent } from "pages/Parents/components/PatientsCol"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import moment from "moment"
import { convertTo12HourFormat } from "helpers/commonHelpers"
import { IoMdCheckmark, IoIosClose } from "react-icons/io";
import CommonModalHOC from "components/Common/CommonModalHOC";

const VirtualAppointments = props => {
  //meta title
  document.title = "Appointments | Supaw"

  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [currentSelected, setCurrentSelected] = useState(-1);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [showCancleAppointment, setShowCancleAppointment] = useState(false);

  const appointments = useSelector(getAppointmentsOfDoc) || []
  const userDetails = useSelector(getUserDetails)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleting, setIsdeleting] = useState(false)
  const history = useHistory();

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new Appointment
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      //   toggle()
    },
  })

  useEffect(() => {
    dispatch(fetchAppointmentTypes())
  }, [dispatch])

  async function fetchData() {
    if (userDetails?._id) {
      dispatch(setGlobalLoading(true))
      await dispatch(getAppointmentsByDocId(userDetails?._id, true))
      dispatch(setGlobalLoading(false))
    }
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const handleAcceptAppointment = async () => {
    try {
        dispatch(setGlobalLoading(true));
        setIsPaymentLoading(true);
        const response = await updateAppointmentPaymentStatus({ id: selectedAppointment?._id, paymentDone: true });
        setIsPaymentLoading(false);
        setShowPaymentStatusModal(false);
        setSelectedAppointment({});
        if (response.status === 1) {
          fetchData();
        }
        dispatch(setGlobalLoading(false));        
    } catch (error) {
        dispatch(setGlobalLoading(false));
        console.error("Error in handleAcceptAppointment:", error);
    }
  }

  const handleRejectAppointment = async () => {
    try {
        dispatch(setGlobalLoading(true));
        setIsPaymentLoading(true);
        const response = await cancleAppointment({ id: selectedAppointment?._id });
        setIsPaymentLoading(false);
        setShowCancleAppointment(false);
        if (response.status === 1) {
          fetchData();
        }
        dispatch(setGlobalLoading(false));        
    } catch (error) {
        dispatch(setGlobalLoading(false));
        console.error("Error in handleRejectAppointment:", error);
    }
  }

  /**
   * Handling click on event on calendar
   */
  const handleDeleteEvent = async () => {
    try {
      setIsdeleting(true)
      const res = await deleteAppointment(currentSelected)
      if (res.status == 1) {
        await dispatch(getAppointmentsByDocId(userDetails?._id))
      }
      setIsdeleting(false)
      setDeleteModal(false)
    } catch (error) {
      setIsdeleting(false)
      setDeleteModal(false)
    }
  }

  /**
   * On delete event
   */
  const handleEventClick = arg => {
    history.push(`/edit-appointment/${arg.event._def.publicId}`)
  }

  const onClickDelete = arg => {
    setCurrentSelected(arg._id)
    setDeleteModal(true)
  }

  const PatientName = cell => {
    return (
      <Link
        to={`/pet/${cell.row?.original?.petObjectId._id}`}
        className="font-fontFamily"
      >
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }

  const columns = useMemo(
    () => [
      {
        Header: "Pets Name",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <PatientName {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Breed",
        accessor: "petObjectId.petBreed",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Type",
        accessor: "petObjectId.petType",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Parent",
        accessor: "parentId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return `${moment(cellProps.row.original.date).format("YYYY-MM-DD")} ${convertTo12HourFormat(cellProps.row.original.time)}`;
        },
      },
      {
        Header: "Booked At",
        accessor: "time",
        filterable: true,
        Cell: cellProps => {
          return `${moment(cellProps.row.original.createdAt).format("YYYY-MM-DD HH:MM A")}`;
        },
      },
      {
        Header: "Visit Type",
        accessor: "visitType",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Cost",
        accessor: "cost",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <StatusName {...cellProps} />
        },
      },
      {
        Header: "Payment Action",
        accessor: "paymentAction",
        disableFilters: true,
        Cell: cellProps => {
            const isAppointmentScheduled = cellProps.row.original.status === "scheduled" && cellProps.row.original.status !== "completed";

            return (
                <div className="d-flex gap-3 justify-content-start align-items-center">
                    {
                        !!cellProps?.row?.original?.paymentDone
                            ? (
                                <>
                                    <p className="text-success">Payment Received</p>
                                </>
                            )
                            : (
                                <>
                                    <button
                                        className={`btn ${isAppointmentScheduled ? "btn-success" : "btn-secondary"} d-flex justify-content-between align-items-center legend-item`}
                                        style={{ height: "30px", width: "80px", padding: "5px 10px" }}
                                        disabled={!isAppointmentScheduled}
                                        type="button"
                                        onClick={() => {
                                          setSelectedAppointment(cellProps?.row?.original);
                                          setShowPaymentStatusModal(true);
                                        }}
                                    >
                                        <div className="d-flex justify-content-center align-items-center h-100 gap-2">
                                            <IoMdCheckmark size={15} />
                                            <div>
                                                Paid
                                            </div>
                                        </div>
                                        <span className="tooltip">Appointment fee Paid.</span>
                                    </button>

                                    <button
                                        className={`btn ${isAppointmentScheduled ? "btn-danger" : "btn-secondary"} d-flex justify-content-between align-items-center legend-item`}
                                        style={{ height: "30px", width: "80px", padding: "5px 10px" }}
                                        disabled={!isAppointmentScheduled}
                                        type="button"
                                        onClick={() => {
                                          setSelectedAppointment(cellProps?.row?.original);
                                          setShowCancleAppointment(true);
                                        }}
                                    >
                                        <div className="d-flex justify-content-center align-items-center h-100 gap-1">
                                            <IoIosClose size={20} />
                                            <div>
                                                Cancle
                                            </div>
                                        </div>
                                        <span className="tooltip">Cancle Appointment</span>
                                    </button>

                                </>
                            )
                    }


                </div>
            )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              {cellProps.row.original.status === "scheduled" &&
              cellProps.row.original.status !== "completed" ? (
                <Link
                  to={`/edit-appointment/${cellProps.row.original._id}`}
                  className="text-info legend-item"
                >
                  <i id="edit1tooltip">
                    <IconSVG icon="edit" />
                  </i>
                  <span className="tooltip">Edit</span>
                </Link>
              ) : null}
              {cellProps.row.original.status === "completed" && (
                <Link
                  to={`/prescription-details/${cellProps.row.original._id}`}
                  className="text-success legend-item"
                >
                  <i id="prec">
                    <IconSVG icon="more" />
                  </i>
                  <span className="tooltip">Prescription</span>
                </Link>
              )}
              {cellProps.row.original.status !== "completed" &&
                cellProps.row.original.status !== "cancelled" && (
                  <Link
                    to={`${
                      cellProps.row.original.status === "checkIn"
                        ? "prescription"
                        : "checkin"
                    }/${cellProps.row.original._id}`}
                    className="text-success legend-item"
                  >
                    <i id="edittooltip">
                      <IconSVG icon="checkin" />
                    </i>
                    <span className="tooltip">
                    {cellProps.row.original.status === "checkIn"
                        ? "Prescription"
                        : "Checkin"}
                    </span>
                  </Link>
                )}

              <Link
                to="#"
                className="text-danger legend-item"
                onClick={() => {
                  const appointmentData = cellProps.row.original
                  onClickDelete(appointmentData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>
                <span className="tooltip">Delete</span>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  );


  console.log("Selected Appointment:", selectedAppointment)

  //Filter todays appoitment data

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      {/* Payment Status Modal */}
      <CommonModalHOC
        show={showPaymentStatusModal}
        title={`Are you sure you received the payment for 
          ${selectedAppointment?.petObjectId?.fullName || ""} 
          at ${moment(selectedAppointment?.date).format("YYYY-MM-DD")} 
          ${convertTo12HourFormat(selectedAppointment?.time)} ?`
        }
        onActionClick={async () => {
          await handleAcceptAppointment();
        }}
        onCloseClick={() => {
          setSelectedAppointment({});
          setShowPaymentStatusModal(false);
        }}
        actionBtnText={"Paid"}
        isLoading={isPaymentLoading}
      />

      {/* Appointment Cancle Modal */}
      <CommonModalHOC
        show={showCancleAppointment}
        title={`Are you sure you want to Cancle the Appointment for 
          ${selectedAppointment?.petObjectId?.fullName || ""} 
          at ${moment(selectedAppointment?.date).format("YYYY-MM-DD")} 
          ${convertTo12HourFormat(selectedAppointment?.time)} ?`
        }
        onActionClick={async () => {
          await handleRejectAppointment();
        }}
        onCloseClick={() => {
          setSelectedAppointment({});
          setShowCancleAppointment(false);
        }}
        actionBtnText={"Cancel Appointment"}
        isLoading={isPaymentLoading}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row className=" mb-4 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                Virtual Appointments
              </h4>
            </Col>

            <Col className="d-flex justify-content-end mt-4">
              <div className="create-link">
                <Link
                  color="primary"
                  className="font-16 btn-block font-fontFamily"
                  to={"/add-appointment"}
                >
                  <i className="mdi mdi-plus-circle-outline me-1 mr-2" />
                  Create New Appointment
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row className="mt-20">
                <TableContainer
                  columns={columns}
                  // data={appointments || []}
                  data={appointments || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  emptyListLink="/add-appointment"
                  emptyText="No Appointments found"
                  emptyLinkText="+ Add Appointment"
                  customPageSize={10}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VirtualAppointments.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default VirtualAppointments
