import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import classnames from "classnames"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import {
  getParentById,
  getAllPetsByParentId,
  fetchAppointmentsListBasedOnPetId,
  BASE,
} from "helpers/api_helper"
import "./style.scss"
import ParentDetailsCard from "./components/ParentDetailsCard"
import PetCompleteDetails from "./components/PetCompleteDetails"
import { setGlobalLoading } from "store/slices"

function PetParent() {
  //meta title
  document.title = "Pet Parents"

  const [parentDetails, setParentDetails] = useState({})
  const [noDetails, setNoDetails] = useState(false)

  const [parentPets, setParentPets] = useState([])

  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const dispatch = useDispatch();

  const { id } = useParams()

  fetchAppointmentsListBasedOnPetId
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  useEffect(() => {
    async function fetchData() {
      dispatch(setGlobalLoading(true));
      const detail = await getParentById({ patientId: id });
      if (detail.status === 1 && detail.data) {
        setParentDetails(detail.data)
        
        const petList = await getAllPetsByParentId(id)
        setParentPets(petList.data)
        setverticalActiveTab(petList.data[0]?._id)
      } else {
        setNoDetails(true)
      }
      dispatch(setGlobalLoading(false));
    }
    fetchData()
  }, [])
  if (noDetails) {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Patients"
              breadcrumbItem={parentDetails?.fullName}
            />
            <Row>
              <Col xs="12">Invalid Parent!!</Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="mx-3">
            <Breadcrumbs
              title="Patients"
              titleLink="/pet-parents"
              breadcrumbItem={parentDetails?.fullName}
            />
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {/* <ParentDetailsCard
                    parentDetails={parentDetails ? parentDetails : {}}
                  /> */}
                  <div >
                    <Row>
                      {/* <Col md="3">
                        <CardBody>
                          <CardTitle className="mb-4 font-fontFamily">
                            {parentDetails.fullName} Pet (s) (
                            {parentPets?.length})
                          </CardTitle>
                          <Nav pills className="flex-column">
                            {parentPets?.length
                              ? parentPets.map((item, index) => {
                                  return (
                                    <NavItem key={index}>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          "mb-2": true,
                                          active:
                                            verticalActiveTab === item._id,
                                        })}
                                        onClick={() => {
                                          toggleVertical(item._id)
                                        }}
                                      >
                                        {item.fullName}
                                        {item.images[0] && (
                                          <img src={item.images[0]} />
                                        )}
                                      </NavLink>
                                    </NavItem>
                                  )
                                })
                              : null}
                          </Nav>
                        </CardBody>
                      </Col> */}
                      <Col md="12">
                        <TabContent
                          activeTab={verticalActiveTab}
                          className="text-muted mt-4 mt-md-0"
                        >
                          {parentPets?.length ? (
                            parentPets.map((item, index) => {
                              return (
                                <TabPane key={index} tabId={item._id}>
                                  <PetCompleteDetails
                                    petDetails={item}
                                    parentDetails={
                                      parentDetails ? parentDetails : {}
                                    }
                                    parentPets={parentPets}
                                    verticalActiveTab={verticalActiveTab}
                                    toggleVertical={toggleVertical}
                                  />
                                </TabPane>
                              )
                            })
                          ) : (
                            <div className="history-doc-cta mb-4 ">
                              <Link
                                className="btn btn-secondary"
                                to={`/add-pet/parent/${parentDetails?._id}`}
                              >
                                + Add Pets
                              </Link>
                            </div>
                          )}
                        </TabContent>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PetParent.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PetParent
