import {
  Autocomplete,
  useJsApiLoader,
  GoogleMap,
  Marker,
  geocodeByPlaceId,
} from "@react-google-maps/api"
import ReactSelect from "react-select"
// import { LocationInput } from '@/types';
import React, { useEffect, useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal } from "reactstrap"
import {
  fetchAllCityBasedOnCountry,
  fetchAllStateBasedOnCountry,
  getCountries,
} from "helpers/api_helper"
import {
  modifyCityArray,
  modifyCountryArray,
  modifyStateArray,
} from "helpers/utils"
import { showMessage } from "components/Notification"
// import { useTranslation } from 'next-i18next';
// import Loader from '@/components/ui/loader/loader';

const libraries = ["places"]

export default function LocationModal({
  isOpen,
  onClose,
  latLong,
  data,
  onChange,
  onAddressValuesSubmit,
}) {
  const [completeAddress, setCompleteAddress] = useState("")
  const [country, setCountry] = useState({})
  const [state, setState] = useState({})
  const [city, setCity] = useState({})
  const [pinCode, setPinCode] = useState("")
  const [floor, setFloor] = useState("")
  const [nearBylandmark, setNearByLandmark] = useState("")
  const [step, setStep] = useState(1)
  const [formattedAddress, setFormattedAddress] = useState("")
  console.log("City", city)
  const addresssValues = {
    completeAddress: completeAddress,
    floor: floor,
    landMark: nearBylandmark,
    country: "India",
    state: state.value,
    city: city.value,
    pinCode: pinCode,
  }

  const handleNextStep = () => {
    if (step < 2) {
      setStep(step + 1)
    } else {
      onAddressValuesSubmit(addresssValues)
      onClose() // Close the modal after completing the last step
    }
  }

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1)
    } else {
      onClose() // Close the modal if the user goes back from the first step
    }
  }
  //   const { t } = useTranslation();
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google_map_autocomplete",
    googleMapsApiKey: "AIzaSyBskR2t4VZZ_ptWYPSmwoG12Xna2mH8iU0",
    libraries,
  })

  const [autocomplete, setAutocomplete] = useState(null)
  const [map, setMap] = useState(null)
  const [marker, setMarker] = useState(null)
  const [currentAddress, setCurrentAddress] = useState(data)
  useEffect(() => {
    setCurrentAddress(data)
  }, [data])
  // console.log("currentAddress: " + currentAddress)
  const mapContainerStyle = {
    width: "100%",
    height: step === 2 ? "150px" : "400px",
  }

  const center = {
    lat: latLong[1], // Set the latitude from the latLong prop
    lng: latLong[0], // Set the longitude from the latLong prop
  }

  const mapOptions = {
    // Customize the map controls and appearance if needed
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  }

  const onLoad = React.useCallback(function callback(autocompleteInstance) {
    setAutocomplete(autocompleteInstance)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setAutocomplete(null)
  }, [])

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace()

    if (!place.geometry || !place.geometry.location) {
      return
    }

    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      formattedAddress: place.formatted_address,
    }

    // Update the map center and marker position
    if (map && marker) {
      map.panTo(location)
      marker.setPosition(location)
    } else {
      center.lat = location.lat
      center.lng = location.lng
    }

    if (onChange) {
      onChange(location)
    }

    setShowMap(true) // Show the map when a location is selected
  }

  if (loadError) {
    return <div>Map cannot be loaded.</div>
  }
  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBskR2t4VZZ_ptWYPSmwoG12Xna2mH8iU0`
      )
      const data = await response.json()
      const address = data?.results?.[0]?.formatted_address || ""
      setFormattedAddress(address)
    } catch (error) {
      console.error("Error fetching reverse geocode data:", error)
    }
  }

  const onDragEnd = e => {
    const location = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
      formattedAddress: formattedAddress,
    }

    if (onChange) {
      onChange(location)
    }
  }

  useEffect(() => {
    // Update formattedAddress when latLong changes
    reverseGeocode(latLong[1], latLong[0])
  }, [latLong])

  const handleSubmit = event => {
    event.preventDefault()
    try {
      if (Object.keys(state).length === 0) {
        showMessage({
          label: "Please select State to Continue ",
          status: "warning",
        })
        return
      }
      if (Object.keys(city).length === 0) {
        showMessage({
          label: "Please select City to Continue",
          status: "warning",
        })
        return
      }
    } catch (error) {
      setIsLoading(false)
      showMessage({ label: "Somthing Went Wrong", status: "error" })
    }
    handleNextStep()
  }

  const [countries, setCountries] = useState([])
  console.log("CountriesArray", countries)
  useEffect(() => {
    const getCountry = async () => {
      const res = await getCountries()
      setCountries(res.data)
    }
    const getStates = async () => {
      const obj = {
        COUNTRY_NAME: "India",
      }
      const res = await fetchAllStateBasedOnCountry(obj)
      setStateArray(res.data)
    }

    getStates()
    getCountry()
  }, [])

  useEffect(() => {
    const getCitys = async () => {
      const res = await fetchAllCityBasedOnCountry({
        state: state.value,
      })
      setCityArray(res.data)
    }
    getCitys()
  }, [state])
  const [stateArray, setStateArray] = useState([])
  console.log("AllStates", stateArray)
  console.log("Country", country)

  const [cityArray, setCityArray] = useState([])
  console.log("CityArray", cityArray)

  const sortedStateArray = modifyStateArray(stateArray).sort((a, b) =>
    a?.label?.localeCompare(b.label)
  )
  const sortedCityArray = modifyCityArray(cityArray).sort((a, b) =>
    a?.label?.localeCompare(b.label)
  )
  return isLoaded ? (
    <div>
      <Modal isOpen={isOpen}>
        <div className="d-flex flex-column">
          <div style={{ width: "460px" }} className="m-auto my-4">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={14} // Set the default zoom level for the map
              options={mapOptions}
              onLoad={mapInstance => setMap(mapInstance)}
            >
              <Marker
                position={center}
                draggable={true} // Allow the marker to be dragged to select the precise location
                onDragEnd={onDragEnd}
                onLoad={markerInstance => setMarker(markerInstance)}
              />
            </GoogleMap>
          </div>
          {step === 1 && (
            <>
              <div className="d-flex align-items-center justify-content-between px-3">
                <h3>Set your location</h3>
                <button className="btn-close" onClick={onClose}></button>
              </div>
              <div>
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  onUnmount={onUnmount}
                  fields={[
                    "address_components",
                    "geometry.location",
                    "formatted_address",
                  ]}
                  className="px-3"
                >
                  <div>
                    <Label
                      htmlFor="categoryName"
                      className="col-form-label text-input-label font-fontFamily text-sm"
                      style={{ fontSize: "12px", color: "#6C6C70" }}
                    >
                      Address
                    </Label>
                    <Input
                      type="text"
                      placeholder={"Search your location"}
                      value={currentAddress}
                      onChange={e => setCurrentAddress(e.target.value)}
                      disabled={true}
                    />
                  </div>
                </Autocomplete>

                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    onClick={handleNextStep}
                    className="m-3 ml-auto"
                    color="danger"
                  >
                    Confirm and Proceed
                  </Button>
                </div>

                {/* <Button onClick={onClose}>Cancel</Button> */}
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="d-flex align-items-center justify-content-between px-3">
                <h3>Add Address</h3>
                <button className="btn-close" onClick={onClose}></button>
              </div>
              <div>
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  onUnmount={onUnmount}
                  fields={[
                    "address_components",
                    "geometry.location",
                    "formatted_address",
                  ]}
                  className="px-3"
                >
                  <Form className="mb-0 gap-0" onSubmit={handleSubmit}>
                    <FormGroup style={{ marginBottom: "0px", padding: "0px" }}>
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        Address<span className="text-danger fs-5">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder={"Search your location"}
                        value={currentAddress}
                        style={{ marginBottom: "0" }}
                        onChange={e => setCurrentAddress(e.target.value)}
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0",
                        marginTop: "0",
                      }}
                    >
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        Complete Address{" "}
                        <span className="text-danger fs-5">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder={"Enter Your Complete Address"}
                        value={completeAddress}
                        onChange={e => setCompleteAddress(e.target.value)}
                        // defaultValue={address}
                        required
                      />
                    </FormGroup>
                    <FormGroup
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0",
                        marginTop: "0",
                      }}
                    >
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        Country<span className="text-danger fs-5">*</span>
                      </Label>
                      <ReactSelect
                        type="text"
                        placeholder={"Enter Country"}
                        value={[{ value: "India", label: "India" }]}
                        onChange={e => setCountry(e.target.value)}
                        // defaultValue={address}
                        required
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        options={[{ value: "India", label: "India" }]}
                        isDisabled={true}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0",
                        marginTop: "0",
                      }}
                    >
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        State<span className="text-danger fs-5">*</span>
                      </Label>
                      <ReactSelect
                        type="text"
                        placeholder={"Enter State"}
                        value={state}
                        onChange={setState}
                        // defaultValue={address}
                        required
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        options={sortedStateArray}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0",
                        marginTop: "0",
                      }}
                    >
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        City<span className="text-danger fs-5">*</span>
                      </Label>
                      <ReactSelect
                        type="text"
                        placeholder={"Enter City"}
                        value={city}
                        onChange={setCity}
                        // defaultValue={address}
                        required
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        options={sortedCityArray}
                        isDisabled={cityArray.length === 0 ? true : false}
                      />
                    </FormGroup>
                    <FormGroup
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0",
                        marginTop: "0",
                      }}
                    >
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        Pin Code<span className="text-danger fs-5">*</span>
                      </Label>
                      <Input
                        type="text" // Use "text" instead of "number" for better control
                        placeholder={"Enter Pin"}
                        value={pinCode}
                        onChange={e => {
                          const value = e.target.value
                          // Allow only digits (0-9) and ensure the length doesn't exceed 8 digits
                          if (/^\d*$/.test(value) && value.length <= 8) {
                            setPinCode(value)
                          }
                        }}
                        required
                        maxLength={8} // Set max length to 8 digits (100000000 has 8 digits)
                        inputMode="numeric" // Ensures a numeric keypad on mobile devices
                      />
                    </FormGroup>
                    <FormGroup
                      style={{
                        marginBottom: "0px",
                        paddingBottom: "0",
                        marginTop: "0",
                      }}
                    >
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        Floor (optional)
                      </Label>
                      <Input
                        type="text"
                        placeholder={"Enter your Floor Number"}
                        value={floor}
                        onChange={e => setFloor(e.target.value)}
                        // defaultValue={latLong}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        htmlFor="categoryName"
                        className="col-form-label text-input-label font-fontFamily text-sm"
                        style={{
                          fontSize: "12px",
                          color: "#6C6C70",
                          paddingBottom: "0",
                          paddingTop: "0",
                        }}
                      >
                        Nearby Landmark (optional)
                      </Label>
                      <Input
                        type="text"
                        placeholder={"Enter Nearby Landmark"}
                        value={nearBylandmark}
                        onChange={e => setNearByLandmark(e.target.value)}
                        // defaultValue={latLong}
                      />
                    </FormGroup>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        onClick={handlePrevStep}
                        color="danger"
                        className="m-3 ml-auto"
                      >
                        Back
                      </Button>
                      <Button
                        color="danger"
                        className="m-3 ml-auto"
                        type="submit"
                      >
                        Save and Proceed
                      </Button>
                    </div>
                  </Form>
                </Autocomplete>

                {/* <Button onClick={onClose}>Cancel</Button> */}
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  ) : (
    // "<Loader simple={true} className='h-6 w-6' />"
    ""
  )
}
