import React, { useState } from "react"
import { Card, ListGroup, Dropdown, ButtonGroup } from "react-bootstrap"
import { FaCaretDown } from "react-icons/fa"

const AppointmentsCard = ({ appointments }) => {
  const [appointmentType, setAppointmentType] = useState("All Appointments")
  const [status, setStatus] = useState("Open")
  const [visibleCount, setVisibleCount] = useState(5) // Number of appointments to show initially

  // Filter appointments based on the selected type and status
  const filteredAppointments = appointments.filter(appointment => {
    const matchesType =
      appointmentType === "All Appointments" ||
      appointment.type === appointmentType
    const matchesStatus =
      status === "Open" ? !appointment.completed : appointment.completed
    return matchesType && matchesStatus
  })

  // Function to handle show more button click
  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 5) // Increase the count by 5
  }

  return (
    <Card
      className="custom-card mb-4 appoinment"
      style={{ borderRadius: "15px" }}
    >
      <Card.Body>
        {/* Header */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="card-title">Appointments</h5>
          <span style={{ color: "#F26D21", fontWeight: "bold" }}>• Dog</span>
        </div>

        {/* Dropdowns */}
        <div className="d-flex justify-content-between gap-3 mb-3 flex-wrap">
          <Dropdown as={ButtonGroup} className="flex-grow-1 mb-2">
            <Dropdown.Toggle variant="light" className="dropdown-toggle w-100">
              {appointmentType} <FaCaretDown />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ marginTop: "35px" }}>
              <Dropdown.Item
                onClick={() => setAppointmentType("All Appointments")}
              >
                All Appointments
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setAppointmentType("Dog")}>
                Dog
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setAppointmentType("Cat")}>
                Cat
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={ButtonGroup} className="flex-grow-1 mb-2">
            <Dropdown.Toggle variant="light" className="dropdown-toggle w-100">
              {status} <FaCaretDown />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ marginTop: "35px" }}>
              <Dropdown.Item onClick={() => setStatus("Open")}>
                Open
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setStatus("Completed")}>
                Completed
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Appointments List */}
        <ListGroup
          variant="flush"
          style={{ overflowY: "hidden", maxHeight: "300px" }}
        >
          {filteredAppointments
            .slice(0, visibleCount)
            .map((appointment, index) => (
              <ListGroup.Item key={index} className="d-flex align-items-center">
                <div
                  style={{
                    borderLeft: "5px solid #F26D21",
                    paddingLeft: "10px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div className="text-truncate">
                    <strong>{appointment.name}</strong> - {appointment.service}
                  </div>
                  <small className="text-muted">
                    {appointment.time} • {appointment.duration}
                  </small>
                </div>
              </ListGroup.Item>
            ))}
        </ListGroup>

        {/* Show More Button */}
        {visibleCount < filteredAppointments.length && (
          <div className="text-center mt-3">
            <button className="btn btn-primary" onClick={handleShowMore}>
              Show More
            </button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default AppointmentsCard
