import React from "react"
import { Redirect } from "react-router-dom"

// Pages Component
import AddAppointment from "../pages/Appointments/AddAppointment"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Logout from "../pages/Authentication/Logout"
import Login from "../pages/Authentication/Login"
import Register2 from "../pages/Authentication/Register"
import ForgetPwd2 from "../pages/Authentication/ForgetPassword"
import ChangePassword from "pages/Authentication/ChangePassword"
import RegisterStep2 from "pages/Authentication/RegisterStep2"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

import PetParentList from "pages/Parents/AllParents"
import PetParent from "pages/Parents/ParentDetails"
import CreateParent from "../pages/Parents/AddParent"

// Patients

import PetsList from "../pages/Pets/AllPets"

import CreatePet from "../pages/Pets/AddPet"

import Timings from "pages/Administration/Timings"
import Appointments from "pages/Appointments/Appointments"
import Checkin from "pages/Appointments/Checkin"
import Prescription from "pages/Appointments/Prescription"
import invoicesDetail from "pages/Appointments/PrescriptionPdf/Presctiption-details"
import InvoiceDetails2 from "pages/Billings/InvoicePDF/Invoice-pdf"
import VideoChat from "pages/VideoScreens"

import ImagingRequests from "pages/Imaging/Requests"
import ImagingCompleted from "pages/Imaging/Completed"
import AddImagingRequests from "pages/Imaging/AddImaging"

import LabRequests from "pages/Labs/Requests"
import LabCompleted from "pages/Labs/Completed"
import AddLabRequests from "pages/Labs/AddLabs"
import AllAppointments from "pages/Appointments/AllAppointments"
import Inventory from "pages/Inventory/Inventory"
import Pricing from "pages/Billings/Pricing"
import PricingCategory from "pages/Administration/PricingCategory"
import AddPricing from "pages/Billings/AddPricing"
import ReceivedItems from "pages/Inventory/ReceivedItems"
import AddNewItems from "pages/Inventory/AddNewitem"
import AddNewCategory from "pages/Inventory/AddNewCategories"
import AddAttributeSet from "pages/Inventory/AddAttributeSets"
import TestDetails from "pages/Billings/TestDetails"
import Invoices from "pages/Billings/Invoices"
import AddNewInvoice from "pages/Billings/AddInvoice"
import AddPayment from "pages/Billings/AddPayment"
import AddManufacturers from "pages/Inventory/Addmanufacturers"
import AddAisleDetails from "pages/Inventory/AddAisle"
import AddInventoryType from "pages/Inventory/AddInventoryType"
import ItemDetails from "pages/Inventory/InventoryItemDetails"
import AppointmentDetails from "pages/Appointments/AppointmentDetails"
import PetDetails from "pages/Pets/PetDetails"
import PurchaseOrder from "pages/Billings/PurchaseOrder"
import AddNewPurchaseOrder from "pages/Billings/AddPurchaseOrder"
import PurchaseOrderDetails from "pages/Billings/PurchaseOrderDetails"
import RegisterStep3 from "pages/Authentication/RegisterStep3"
import RegisterStep4 from "pages/Authentication/RegisterStep4"
import NotFound from "pages/404/NotFound"
import AllPharmacyRequest from "pages/Pharmacy/Requests"
import AddPharMacy from "pages/Pharmacy/AddPharmacy"
import ReIssuePharmacy from "pages/Pharmacy/Re-issuePharmacy"
import DispencePharmacy from "pages/Pharmacy/DispencePharmacy"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "react-big-calendar/dist/react-big-calendar.esm.js"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //Appointment
  { path: "/add-appointment", component: AddAppointment },
  { path: "/all-appointment", component: AllAppointments },
  { path: "/add-appointment/pet/:petId", component: AddAppointment },
  { path: "/edit-appointment/:id", component: AddAppointment },
  { path: "/appointments", component: Appointments },
  { path: "/checkin/:id", component: Checkin },
  { path: "/prescription/:id", component: Prescription },
  { path: "/appointment/:itemId", component: AppointmentDetails },
  { path: "/add-patient-appointment/:petParentId/:paramsPetId", component: AddAppointment },

  { path: "/prescription-details/:id", component: invoicesDetail },
  // { path: "/video-call", component: VideoChat },

  //Patients
  { path: "/patients", component: PetsList },
  { path: "/add-pet", component: CreatePet },
  { path: "/add-pet/parent/:parentId", component: CreatePet },
  { path: "/edit-patient/:id", component: CreatePet },
  { path: "/pet/:itemId", component: PetDetails },

  // parents
  { path: "/add-parent", component: CreateParent },
  { path: "/edit-parent/:id", component: CreateParent },
  { path: "/pet-parents", component: PetParentList },
  { path: "/parent/:id", component: PetParent },

  //pharmacy
  {
    path: "/pharmacy-request",
    component: AllPharmacyRequest,
  },
  {
    path: "/re-issue-pharmacy",
    component: ReIssuePharmacy,
  },
  {
    path: "/dispense-pharmacy",
    component: DispencePharmacy,
  },
  {
    path: "/add-pharmacy-request",
    component: AddPharMacy,
  },

  // Inventory
  { path: "/inventory", component: Inventory },
  { path: "/inventory-items-recieved", component: ReceivedItems },
  { path: "/edit-inventory-items-recieved/:itemId", component: ReceivedItems },
  { path: "/inventory-add-item", component: AddNewItems },
  { path: "/inventory-add-category", component: AddNewCategory },
  { path: "/inventory-add-attributeSet", component: AddAttributeSet },
  { path: "/add-manufacturers", component: AddManufacturers },
  { path: "/edit-manufacturers/:manufacturerId", component: AddManufacturers },
  { path: "/add-aisle-details", component: AddAisleDetails },
  { path: "/edit-aisle-details/:aisleId", component: AddAisleDetails },
  { path: "/add-inventoryType", component: AddInventoryType },
  { path: "/edit-inventoryType/:typeId", component: AddInventoryType },
  { path: "/itemDetails/:itemId", component: ItemDetails },
  // Imaging
  { path: "/imaging-requests", component: ImagingRequests },
  { path: "/imaging-completed", component: ImagingCompleted },
  { path: "/add-imaging-request", component: AddImagingRequests },
  {
    path: "/edit-imaging-request/:imagingId",
    component: AddImagingRequests,
  },

  // labs
  { path: "/lab-requests", component: LabRequests },
  { path: "/labs-completed", component: LabCompleted },
  { path: "/add-lab-request", component: AddLabRequests },
  {
    path: "/edit-lab-request/:labId",
    component: AddLabRequests,
  },

  // Billing
  { path: "/pricing", component: Pricing },
  { path: "/add-pricing/:priceCategory", component: AddPricing },
  { path: "/edit-pricing/:itemId", component: AddPricing },
  { path: "/pricingItem/:itemId", component: TestDetails },
  { path: "/invoices", component: Invoices },
  { path: "/add-new-invoice", component: AddNewInvoice },
  { path: "/add-payment", component: AddPayment },
  { path: "/purchase-order", component: PurchaseOrder },
  { path: "/add-purchase-order", component: AddNewPurchaseOrder },
  { path: "/purchase-order-details/:itemId", component: PurchaseOrderDetails },
  { path: "/invoices-details/:id", component: InvoiceDetails2 },

  // Administration
  { path: "/profile", component: UserProfile },
  { path: "/timings", component: Timings },
  { path: "/pricing-Category", component: PricingCategory },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd2 },
  { path: "/register", component: Register2 },
  { path: "/register-step2", component: RegisterStep2 },
  { path: "/register-step3", component: RegisterStep3 },
  { path: "/register-step4", component: RegisterStep4 },
  { path: "/change-password", component: ChangePassword },
  { path: "/video-call/:videoId", component: VideoChat },
  // {
  //   path: "*",
  //   componet: NotFound,
  // },
]

export { authProtectedRoutes, publicRoutes }

// cheching, rescheual, cancel
