// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-strength-meter {
  margin-top: 20px;
}

.input-container {
  /* display: flex; */
  align-items: center;
  /* Center items vertically */
  position: relative;
}

.password-input {
  padding-right: 2.5rem;
  /* Space for the eye icon on the right */
  width: 100%;
  box-sizing: border-box;
  /* Ensures padding is included in width calculation */
}

.password-visibility-toggle {
  position: absolute;
  top: 0.767rem;
  right: 2rem;
  /* Space from the right edge, adjust as needed */
  cursor: pointer;
  color: #000;
  /* Adjust icon color if needed */
  font-size: 1rem;
  /* Adjust size if needed */
}`, "",{"version":3,"sources":["webpack://./src/pages/Authentication/register.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,wCAAwC;EACxC,WAAW;EACX,sBAAsB;EACtB,qDAAqD;AACvD;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,gDAAgD;EAChD,eAAe;EACf,WAAW;EACX,gCAAgC;EAChC,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".password-strength-meter {\n  margin-top: 20px;\n}\n\n.input-container {\n  /* display: flex; */\n  align-items: center;\n  /* Center items vertically */\n  position: relative;\n}\n\n.password-input {\n  padding-right: 2.5rem;\n  /* Space for the eye icon on the right */\n  width: 100%;\n  box-sizing: border-box;\n  /* Ensures padding is included in width calculation */\n}\n\n.password-visibility-toggle {\n  position: absolute;\n  top: 0.767rem;\n  right: 2rem;\n  /* Space from the right edge, adjust as needed */\n  cursor: pointer;\n  color: #000;\n  /* Adjust icon color if needed */\n  font-size: 1rem;\n  /* Adjust size if needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
