// import classNames from "classnames"
// import React, { useEffect, useState } from "react"
// import { UncontrolledTooltip } from "reactstrap"
// import { CopyToClipboard } from "react-copy-to-clipboard"

// import Participant from "./Participant"
// import { BASE } from "helpers/api_helper"

// const Room = ({
//   roomName,
//   room,
//   handleLogout,
//   isFullScreen,
//   onFullScreen,
//   isFromSeperateWindow,
// }) => {
//   const [participants, setParticipants] = useState([])

//   useEffect(() => {
//     const participantConnected = participant => {
//       setParticipants(prevParticipants => [...prevParticipants, participant])
//     }

//     const participantDisconnected = participant => {
//       setParticipants(prevParticipants =>
//         prevParticipants.filter(p => p !== participant)
//       )
//     }

//     room.on("participantConnected", participantConnected)
//     room.on("participantDisconnected", participantDisconnected)
//     room.participants.forEach(participantConnected)
//     return () => {
//       room.off("participantConnected", participantConnected)
//       room.off("participantDisconnected", participantDisconnected)
//     }
//   }, [room])

//   const remoteParticipants = participants.map(participant => (
//     <Participant key={participant.sid} participant={participant} />
//   ))

//   return (
//     <div className="room">
//       <UncontrolledTooltip placement="top" target="closeVideo">
//         End Virtual Visit
//       </UncontrolledTooltip>
//       <button
//         className="video-phone video-phone--end"
//         id="closeVideo"
//         onClick={handleLogout}
//       >
//         <i className="mdi mdi-phone-outline" />
//       </button>
//       {!isFromSeperateWindow && (
//         <UncontrolledTooltip placement="top" target="fullscreen">
//           {!isFullScreen ? "Expand" : "Collapse"}
//         </UncontrolledTooltip>
//       )}
//       {!isFromSeperateWindow && (
//         <button
//           className={classNames("video-fullscreen", {
//             "video-fullscreen--full": isFullScreen,
//           })}
//           id="fullscreen"
//           onClick={onFullScreen}
//         >
//           {!isFullScreen ? (
//             <i className="mdi mdi-arrow-expand-all" />
//           ) : (
//             <i className="mdi mdi-arrow-collapse-all" />
//           )}
//         </button>
//       )}
//       <UncontrolledTooltip placement="top" target="videolink">
//         Copy Link
//       </UncontrolledTooltip>
//       <CopyToClipboard text={`${window.location.host}/video-call/${roomName}`}>
//         <button className={classNames("video-link")} id="videolink">
//           <i className="mdi mdi-link-variant" />
//         </button>
//       </CopyToClipboard>

//       <div className="local-participant">
//         {room ? (
//           <Participant
//             key={room.localParticipant.sid}
//             participant={room.localParticipant}
//           />
//         ) : (
//           ""
//         )}
//       </div>
//       {/* <h3>Remote Participants</h3> */}
//       <div className="remote-participants">{remoteParticipants}</div>
//     </div>
//   )
// }

// export default Room

import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Draggable from "react-draggable"

import Participant from "./Participant"

const Room = ({
  roomName,
  room,
  handleLogout,
  isFullScreen,
  onFullScreen,
  isFromSeperateWindow,
}) => {
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant])
    }

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      )
    }

    room.on("participantConnected", participantConnected)
    room.on("participantDisconnected", participantDisconnected)
    room.participants.forEach(participantConnected)
    return () => {
      room.off("participantConnected", participantConnected)
      room.off("participantDisconnected", participantDisconnected)
    }
  }, [room])

  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} />
  ))

  return (
    <div className="room" style={{ position: "relative" }}>
      <UncontrolledTooltip placement="top" target="closeVideo">
        End Virtual Visit
      </UncontrolledTooltip>
      <button
        className="video-phone video-phone--end"
        id="closeVideo"
        onClick={handleLogout}
      >
        <i className="mdi mdi-phone-outline" />
      </button>
      {!isFromSeperateWindow && (
        <>
          <UncontrolledTooltip placement="top" target="fullscreen">
            {!isFullScreen ? "Expand" : "Collapse"}
          </UncontrolledTooltip>
          <button
            className={classNames("video-fullscreen", {
              "video-fullscreen--full": isFullScreen,
            })}
            id="fullscreen"
            onClick={onFullScreen}
          >
            {!isFullScreen ? (
              <i className="mdi mdi-arrow-expand-all" />
            ) : (
              <i className="mdi mdi-arrow-collapse-all" />
            )}
          </button>
        </>
      )}
      <UncontrolledTooltip placement="top" target="videolink">
        Copy Link
      </UncontrolledTooltip>
      <CopyToClipboard text={`${window.location.host}/video-call/${roomName}`}>
        <button className={classNames("video-link")} id="videolink">
          <i className="mdi mdi-link-variant" />
        </button>
      </CopyToClipboard>

      {/* Draggable Local Participant */}
      <Draggable bounds="parent">
        <div
          className="local-participant"
          style={{
            position: "absolute",
            // bottom: 500,
            // border: "2px solid red", // Debugging border
            // backgroundColor: "white", // Background color for better visibility
            zIndex: 1000, // Ensure it's on top
            width: "150px", // Ensure it has a visible size
            height: "150px", // Ensure it has a visible size
            borderRadius: "10px", // Rounded corners
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Optional: Add shadow for better visibility
          }}
        >
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
            />
          ) : (
            <div>No local participant</div> // Fallback content
          )}
        </div>
      </Draggable>

      <div className="remote-participants">{remoteParticipants}</div>
    </div>
  )
}

export default Room
