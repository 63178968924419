import ButtonMain from "components/Common/Button"
import {
  addImagingRequest,
  BASE,
  createManufacturer,
  deleteManufacturer,
  getAllPetsByParentId,
  getImagingAndLabsBasedOnId,
  getManufacturerById,
  updateImagingRequest,
  updateManufacturer,
  uploadAttachment,
} from "helpers/api_helper"

import { modifyTestTypeOptions } from "helpers/utils"
import * as Yup from "yup"
import { useFormik } from "formik"

import React, { useEffect, useMemo, useReducer, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showMessage } from "components/Notification"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import {
  getAllManufacturers,
  getAllParents,
  getManufacturers,
  getParentList,
  getpricing,
  getPricingDetails,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import { IconSVG } from "components/Common/IconSvg"
import {
  BillingName,
  OrderId,
  Total,
} from "pages/Parents/components/PatientsCol"
import { NumberVal } from "pages/Pets/components/PatientsCol"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"
import ReactSelect from "react-select"

const AddPharmacy = () => {
  //meta title
  document.title = "Re-Issue Pharmacy"
  // const appointmentType = useSelector(getServices)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})

  const [selectedManufacturer, setSelectedManufacturer] = useState(null)
  const userDetails = useSelector(getUserDetails)
  const manufacturers = useSelector(getAllManufacturers)
  const [deleteModal, setDeleteModal] = useState(false)

  const [testType, settestType] = useState()
  const [testName, setTestName] = useState(null)
  const [selectedParent, setSelectedParent] = useState({})
  const [typeOfModal, setTypeOfModal] = useState("")
  const [patientTests, setPatientTests] = useState(details?.labTests || [])
  const [modal, setModal] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [selectedStatus, setStatus] = useState({
    label: "Requested",
    value: "Requested",
  })

  const [ImageUrls, setImageUrl] = useState([])
  const { manufacturerId } = useParams()
  console.log("ma", manufacturerId)
  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: details?.name || "",
      manufacturerName: details?.manufacturerName,
      email: details?.email || "",
      address: details?.address || "",
      phone: details?.phone || "",
      otherDetails: details?.otherDetails || "",
      panDetails: details?.panDetails || "",
      taxId: details?.taxId || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Supplier Name "),
      manufacturerName: Yup.string().required(
        "Please Enter Manufacturer name "
      ),
      email: Yup.string().email().required("Please Enter Email Address "),
      address: Yup.string().required("Please Enter Address "),
      phone: Yup.string()
        .required("Please Enter phone Number")
        .matches(/^\d{10}$/, "Phone number must be 10 digits"),
      taxId: Yup.string()
        .required("Please Enter Tax Id ")
        .matches(
          /^[a-zA-Z0-9]+$/,
          "Only alphabets, numbers, and underscores are allowed"
        ),
    }),
    onSubmit: async values => {
      const obj = {
        ...values,
        vetId: userDetails._id,
      }

      handleSubmit(obj)
      console.log(obj)
    },
  })

  const history = useHistory()
  useEffect(() => {
    async function fetchData() {
      if (!manufacturerId) return
      dispatch(setGlobalLoading(true))
      const _detail = await getManufacturerById(manufacturerId)
      setDetails(_detail.data)
      console.log("manufacturer", _detail)
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [manufacturerId])

  const handleSubmit = async values => {
    console.log(values)
    // return
    try {
      setIsLoading(true)
      let res
      if (!manufacturerId) {
        res = await createManufacturer(values)
      } else {
        res = await updateManufacturer(manufacturerId, values)
      }

      if (res.status === 1) {
        showMessage({ label: res.message, status: "success" })
        dispatch(getManufacturers({ vetId: userDetails?._id }))
        validation.resetForm()
        if (manufacturerId) {
          history.push("/inventory-items-recieved")
        }
      } else {
        showMessage({
          label: res.message || "something went wrong",
          status: "error",
        })
      }
      setIsLoading(false)
    } catch (error) {
      showMessage({
        label: error.response.data.message || "something went wrong",
        status: "error",
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    async function fetchManufacturerData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getManufacturers({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchManufacturerData()
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Notes",
        accessor: "otherDetails",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(
                    `/edit-manufacturers/${cellProps.row.original._id}`
                  )
                }}
              >
                <i id="edittooltip">
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const onClickDelete = manufacturer => {
    setSelectedManufacturer(manufacturer)
    setDeleteModal(true)
  }

  const handleDeleteOrder = async () => {
    // if (order._id) {
    const res = await deleteManufacturer(selectedManufacturer._id)
    if (res.status == 1) {
      dispatch(getManufacturers({ vetId: userDetails?._id }))
      // location.reload()
    }
    setDeleteModal(false)
  }
  const handleCancel = () => {
    validation.resetForm()
  }

  console.log("manufacturers", manufacturers)
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row className="mx-2 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                {manufacturerId ? "Edit Pharmacy Request" : "Re-Issue Pharmacy"}
              </h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4"></Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumbs
            title="Inventory"
            titleLink="/inventory"
            breadcrumbItem={manufacturerId ? "Update Supplier" : "Add Supplier"}
          /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              placeholder="Enter Patient Name"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Appointment Type
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              //   value={ReasonType}
                              //   onChange={handleVisitTypeChange}
                              //   options={reasonOptions}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              //   onMenuOpen={handleVisitTypeMenuOpen}
                            />
                            {validation.touched.manufacturerName &&
                            validation.errors.manufacturerName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.manufacturerName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="email"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Pharmacy<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="email"
                              name="email"
                              type="email"
                              value={validation.values.email || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              placeholder="Enter Pharmacy Name"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="address"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Prescription Date
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <input
                              className="form-control"
                              type="date"
                              //   value={values.adoptedOn || ""}
                              //   onChange={stateChange("adoptedOn")}
                              id="example-date-input"
                              //   max={todayDate}
                              onKeyDown={e => e.preventDefault()}
                              //   min={values.dateOfBirth}
                              //   disabled={!values.dateOfBirth}
                            />
                            {validation.touched.address &&
                            validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="otherDetails"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Prescription
                          </Label>
                          <div className="col-lg-7">
                            <textarea
                              className="form-control"
                              id="otherDetails"
                              rows="8"
                              name="otherDetails"
                              style={{ resize: "none", minHeight: 100 }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.otherDetails || ""}
                              placeholder="Prescription"
                            />
                            {validation.touched.notes &&
                            validation.errors.notes ? (
                              <FormFeedback type="invalid">
                                {validation.errors.notes}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="phone"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Quantity Requested
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="phone"
                              name="phone"
                              type="number"
                              value={validation.values.phone || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              //   placeholder="Enter Phone Number"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.phone &&
                                validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="panDetails"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Refill
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="panDetails"
                              name="panDetails"
                              type="string"
                              value={validation.values.panDetails || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              //   placeholder="Enter PAN"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.panDetails &&
                                validation.errors.panDetails
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.panDetails &&
                            validation.errors.panDetails ? (
                              <FormFeedback type="invalid">
                                {validation.errors.panDetails}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="panDetails"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Bill to
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="panDetails"
                              name="panDetails"
                              type="string"
                              value={validation.values.panDetails || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              //   placeholder="Enter PAN"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.panDetails &&
                                validation.errors.panDetails
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.panDetails &&
                            validation.errors.panDetails ? (
                              <FormFeedback type="invalid">
                                {validation.errors.panDetails}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>

                    <Row className="justify-content-end mt-8 ms-15">
                      <ButtonMain
                        // isLoading={isLoading}
                        type="button"
                        className="btn btn-med btn-primary me-3"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </ButtonMain>

                      <ButtonMain
                        // isLoading={isLoading}
                        type="submit"
                        className="btn btn-med btn-primary"
                      >
                        {manufacturerId ? "Save" : "Create Request"}
                      </ButtonMain>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddPharmacy
