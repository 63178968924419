// // Import required AWS SDK and Web Push modules
// import { SNSClient, CreatePlatformEndpointCommand } from "@aws-sdk/client-sns"

// const registerServiceWorker = async () => {
//   try {
//     // Register the service worker
//     const registration = await navigator.serviceWorker.register("/sw.js") // Ensure 'sw.js' is correctly specified
//     console.log("yuuuu")
//     // Subscribe to push notifications
//     const subscription = await registration.pushManager.subscribe({
//       userVisibleOnly: true,
//       applicationServerKey:
//         "BOXIYmcOKmvy7dbaIPClpafgb6REZ9LBHhK_OYVJ2q3IDr5sd-OPBZJcPSH-_JFNdexk64sA3uaMoIoDDIYL0Qs", // Replace with your VAPID key
//     })

//     console.log(subscription, "subsctipton")
//     // Convert the subscription object to JSON
//     const subscriptionJson = subscription.toJSON()

//     // Extract the device token (endpoint URL)
//     const deviceToken = subscriptionJson.endpoint
//     console.log(deviceToken, "device")
//     // Register the device token with AWS SNS
//     await registerTokenWithSNS(deviceToken)
//   } catch (error) {
//     console.error(
//       "Error registering service worker or subscribing to push notifications",
//       error
//     )
//   }
// }

// // Register the token with AWS SNS
// const registerTokenWithSNS = async deviceToken => {
//   const snsClient = new SNSClient({ region: "ap-south-1" })

//   const params = {
//     PlatformApplicationArn:
//       "arn:aws:sns:your-region:your-account-id:app/WEB/YOUR_APP_NAME", // Replace with your ARN
//     Token: deviceToken,
//   }

//   try {
//     const command = new CreatePlatformEndpointCommand(params)
//     const response = await snsClient.send(command)
//     console.log("Device token registered with SNS:", response.EndpointArn)
//   } catch (err) {
//     console.error("Error registering device token with SNS", err)
//   }
// }

// // console.log("kkiiikk")
// // Call this function on app load or when the user opts into notifications
// // registerServiceWorker()
// export default registerServiceWorker
