import { BASE } from "helpers/api_helper"
import React, { useState, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import Video from "twilio-video"
import Lobby from "./Lobby"
import Room from "./Room"

const VideoChat = ({
  userName,
  roomName,
  onEnd,
  onUserName,
  isFullScreen,
  onFullScreen,
  isFromSeperateWindow,
}) => {
  const [room, setRoom] = useState(null)
  const [connecting, setConnecting] = useState(false)
  const { videoId } = useParams()
  console.log(videoId)

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      setConnecting(true)
      const data = await fetch(`${BASE}video/token`, {
        method: "POST",
        body: JSON.stringify({
          identity: userName,
          room: roomName || videoId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(res => res.json())
      console.log(data, roomName)
      Video.connect(data.token, {
        name: roomName || videoId,
      })
        .then(room => {
          setConnecting(false)
          setRoom(room)
        })
        .catch(err => {
          console.error(err)
          setConnecting(false)
        })
    },
    [roomName, userName]
  )

  const handleLogout = useCallback(() => {
    setRoom(prevRoom => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach(trackPub => {
          trackPub.track.stop()
        })
        prevRoom.disconnect()
      }
      return null
    })
    // onEnd()
  }, [])

  useEffect(() => {
    if (room) {
      const tidyUp = event => {
        if (event.persisted) {
          return
        }
        if (room) {
          handleLogout()
        }
      }
      window.addEventListener("pagehide", tidyUp)
      window.addEventListener("beforeunload", tidyUp)
      return () => {
        window.removeEventListener("pagehide", tidyUp)
        window.removeEventListener("beforeunload", tidyUp)
      }
    }
  }, [room, handleLogout])

  let render
  if (room) {
    render = (
      <Room
        roomName={roomName || videoId}
        room={room}
        handleLogout={handleLogout}
        onFullScreen={!videoId && onFullScreen}
        isFullScreen={isFullScreen || !!videoId}
        isFromSeperateWindow={isFromSeperateWindow}
      />
    )
  } else {
    render = (
      <Lobby
        username={userName}
        roomName={roomName || videoId}
        getUserName={!!videoId}
        handleSubmit={handleSubmit}
        onUserName={onUserName}
        connecting={connecting}
        isFromSeperateWindow={isFromSeperateWindow}
      />
    )
  }
  return render
}

export default VideoChat

// import { BASE } from "helpers/api_helper"
// import React, { useState, useCallback, useEffect } from "react"
// import { useParams } from "react-router-dom"
// import Video from "twilio-video"
// import Lobby from "./Lobby"
// import Room from "./Room"
// import { Spinner, Button } from "react-bootstrap"

// const VideoChat = ({
//   userName,
//   roomName,
//   onEnd,
//   onUserName,
//   isFullScreen,
//   onFullScreen,
//   isFromSeperateWindow,
// }) => {
//   const [room, setRoom] = useState(null)
//   const [connecting, setConnecting] = useState(false)
//   const { videoId } = useParams()

//   const handleSubmit = useCallback(
//     async event => {
//       event.preventDefault()
//       setConnecting(true)
//       try {
//         const res = await fetch(`${BASE}video/token`, {
//           method: "POST",
//           body: JSON.stringify({
//             identity: userName,
//             room: roomName || videoId,
//           }),
//           headers: {
//             "Content-Type": "application/json",
//           },
//         })
//         const data = await res.json()

//         Video.connect(data.token, {
//           name: roomName || videoId,
//         })
//           .then(room => {
//             setConnecting(false)
//             setRoom(room)
//           })
//           .catch(err => {
//             console.error(err)
//             alert("Error connecting to the room. Please try again.")
//             setConnecting(false)
//           })
//       } catch (err) {
//         console.error(err)
//         alert("Failed to fetch video token. Please try again.")
//         setConnecting(false)
//       }
//     },
//     [roomName, userName, videoId]
//   )

//   const handleLogout = useCallback(() => {
//     setRoom(prevRoom => {
//       if (prevRoom) {
//         prevRoom.localParticipant.tracks.forEach(trackPub => {
//           trackPub.track.stop()
//         })
//         prevRoom.disconnect()
//       }
//       return null
//     })
//     // onEnd()
//   }, [])

//   useEffect(() => {
//     if (room) {
//       const tidyUp = event => {
//         if (event.persisted) {
//           return
//         }
//         if (room) {
//           handleLogout()
//         }
//       }
//       window.addEventListener("pagehide", tidyUp)
//       window.addEventListener("beforeunload", tidyUp)
//       return () => {
//         window.removeEventListener("pagehide", tidyUp)
//         window.removeEventListener("beforeunload", tidyUp)
//       }
//     }
//   }, [room, handleLogout])

//   let render
//   if (room) {
//     render = (
//       <div className="video-room">
//         <Room
//           roomName={roomName || videoId}
//           room={room}
//           handleLogout={handleLogout}
//           onFullScreen={!videoId && onFullScreen}
//           isFullScreen={isFullScreen || !!videoId}
//           isFromSeperateWindow={isFromSeperateWindow}
//         />
//       </div>
//     )
//   } else {
//     render = (
//       <div className="lobby-container">
//         {connecting ? (
//           <div className="text-center">
//             <Spinner animation="border" role="status">
//               <span className="sr-only">Connecting...</span>
//             </Spinner>
//             <p>Connecting to the room...</p>
//           </div>
//         ) : (
//           <Lobby
//             username={userName}
//             roomName={roomName || videoId}
//             getUserName={!!videoId}
//             handleSubmit={handleSubmit}
//             onUserName={onUserName}
//             connecting={connecting}
//             isFromSeperateWindow={isFromSeperateWindow}
//           />
//         )}
//       </div>
//     )
//   }
//   return render
// }

// export default VideoChat
