import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { CardTitle, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { IconSVG } from "components/Common/IconSvg"
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const PetDetailsCol = ({ testDetails, isPastAppointment }) => {
  const query = useQuery();
  const appointmentId = query.get("appointmentId");

  const formatDate = dateString => {
    if (!dateString) return "";
  
    // Extract the date part (yyyy-mm-dd)
    const datePart = dateString.split("T")[0];
  
    // Split the date into components
    const [year, month, day] = datePart.split("-");
  
    // Create a Date object
    const date = new Date(dateString);
  
    // Get the day of the month with ordinal suffix
    const getOrdinalSuffix = day => {
      if (day >= 11 && day <= 13) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
    
    const ordinalDay = `${parseInt(day, 10)}${getOrdinalSuffix(parseInt(day, 10))}`;
  
    // Get the abbreviated month name
    const options = { month: 'short' };
    const monthName = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Return the formatted date
    return `${ordinalDay} ${monthName}, ${year}`;
  }
  
  // Example usage
  console.log(formatDate("2024-08-13T16:00:37.000Z")); // Outputs: 13th Aug, 2024
  

  return (
    <ul className="list-unstyled parent-details__container">
      <div className="d-flex justify-content-between">
        <CardTitle className="mb-4 font-fontFamily">
          <h3>Contact Info</h3>
        </CardTitle>
        <div className="history-doc-cta">

          {
            !isPastAppointment && (
              <Link
                to={`/checkin/${appointmentId}`}
                className="font-fontFamily me-2"
              >
                Check In <IconSVG icon="checkin" />
              </Link>
            )
          }
          <Link
            to={`/edit-patient/${testDetails._id}`}
            className="font-fontFamily"
          >
            Edit <IconSVG icon="edit" />
          </Link>
        </div>
      </div>
      <Row>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">
              Patient Name
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.fullName}
            </p>
          </li>
          <li>
            <h6 className="parent-details__title font-fontFamily">Pet Type</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.petType}
            </p>
          </li>
          <li>
            <h6 className="parent-details__title font-fontFamily">Pet Breed</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.petBreed}
            </p>
          </li>
        </Col>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">D.O.B</h6>
            <p className="parent-details__value font-fontFamily">
            {testDetails?.dateOfBirth ? formatDate(testDetails.dateOfBirth) : ''}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Gender</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.gender}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">
              Chip Location
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.locationChipping}
            </p>
          </li>
        </Col>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">Weight</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.weight}K.G
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Spay</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.spay}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Notes</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.notes}
            </p>
          </li>
        </Col>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">
              Pet Parent
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.parentId?.fullName}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Pet Id</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.petId}
            </p>
          </li>
        </Col>
      </Row>
    </ul>
  )
}

PetDetailsCol.propTypes = {
  details: PropTypes.any,
}

export default PetDetailsCol
