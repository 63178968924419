import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import { getAllDiagnosis } from "store/slices"
import ReactSelect from "react-select"
import AsyncSelect from "react-select/async"
import { modifyDiagnosis } from "helpers/utils"
import { BASE } from "helpers/api_helper"
import debounce from "lodash.debounce"
import axios from "axios"

const TypeModal = ({ type, onConfirm, onCloseClick }) => {
  const [checked, setIsChecked] = useState(false)
  const [title, setTitle] = useState("")
  const [addFromList, setAddFromList] = useState(false)
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null)
  const [, setValue] = useState("")

  useEffect(() => {
    setIsChecked(false)
    setTitle("")
  }, [type])

  const handleSubmit = () => {
    handleConfirm()
  }

  const Map = {
    primaryDiagnosis: "Diagnosis",
    patientAllergies: "Allergies",
    operativePlan: "Operative Plan",
  }

  console.log(selectedDiagnosis, "select")

  const handleConfirm = () => {
    const values = {}
    if (type === "primaryDiagnosis") {
      values.type = checked ? "secondary" : "primary"
    }
    values.selectedData = selectedDiagnosis
    if (type === "primaryDiagnosis") {
      values.title = selectedDiagnosis.Term_Name
      values.id = selectedDiagnosis.Code
    } else {
      values.title = title
      values.id = title
    }

    console.log(values, type)
    onConfirm(values, type)
  }

  const handleSecondaryDiagnosis = _checked => {
    setIsChecked(_checked)
  }

  // handle input change event
  const handleInputChange = value => {
    console.log(value)
    setValue(value)
  }
  const debouncedResults = useMemo(() => {
    return debounce(handleInputChange, 1000)
  }, [])

  // handle selection
  const handleChange = value => {
    // setSelectedValue(value)
    setSelectedDiagnosis(value)
  }

  // Debounce the actual API call
  const fetchOptions = debounce(async (inputValue, callback) => {
    try {
      const { data } = await axios.get(
        `${BASE}api/Diagnosis/v1/searchDiagnosis?text=${inputValue}`
      )
      callback(data.data || [])
    } catch (error) {
      console.error("Error fetching options:", error)
      callback([]) // Return an empty array in case of error
    }
  }, 1000)

  // The loadOptions function for AsyncSelect
  const loadOptions = inputValue => {
    return new Promise(resolve => {
      fetchOptions(inputValue, resolve)
    })
  }

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  return (
    <Modal isOpen={!!type} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Col lg={12}>
          <div>
            <h2>Add {Map[type]}</h2>
            <br />
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col lg={12} className="mb-3">
                    <Label className="col-form-label">Title</Label>

                    {/* If it's not primaryDiagnosis */}
                    {type !== "primaryDiagnosis" && (
                      <input
                        name="title"
                        id="ttil"
                        className="form-control"
                        placeholder="Enter title"
                        type="text"
                        onChange={e => setTitle(e.target.value)}
                        value={title || ""}
                      />
                    )}

                    {/* If it's primaryDiagnosis */}
                    {type === "primaryDiagnosis" && (
                      <div className="tooltip-wrapper">
                        <div className="tooltip-target">
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            value={selectedDiagnosis}
                            getOptionLabel={e => e.Term_Name}
                            getOptionValue={e => e.Code}
                            loadOptions={loadOptions}
                            onInputChange={debouncedResults}
                            onChange={handleChange}
                          />
                        </div>
                        <span className="custom-tooltip">
                          Please Type Disease Name For Search
                        </span>
                      </div>
                    )}
                  </Col>

                  {type === "primaryDiagnosis" && (
                    <Col lg={12} className="mb-3">
                      <div className="form-check form-checkbox-outline form-check-warning">
                        <input
                          type="checkbox"
                          defaultChecked={checked}
                          className="form-check-input"
                          onChange={e => {
                            handleSecondaryDiagnosis(e.target.checked)
                          }}
                        />
                        <label className="form-check-label">
                          Secondary Diagnosis
                        </label>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              <Row>
                <Col>
                  <div className="mt-3">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn  btn-primary btn-danger btn-lg ms-2"
                        onClick={onCloseClick}
                      >
                        Cancel
                      </button>
                      {type === "primaryDiagnosis" ? (
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg ms-2"
                          disabled={selectedDiagnosis === null}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg ms-2"
                          disabled={
                            type !== "primaryDiagnosis" && title.length === 0
                          }
                        >
                          Confirm
                        </button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </ModalBody>
    </Modal>
  )
}

TypeModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default TypeModal
