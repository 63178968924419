// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Define colors for each status */
.completed {
  background-color: #3E7D3E;
}

.scheduled {
  background-color: #672b88;
}

.cancelled {
  background-color: #F5222D;
}

.checkIn {
  background-color: #E8A84C;
}

.past {
  background-color: rgba(0, 0, 0, 0.4);
}

.tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  text-wrap: nowrap;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.legend-item:hover .tooltip {
  visibility: visible;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/Appointments/components/Legend.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,gBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEE,kCAAA;AACA;EACE,yBAAA;AACJ;;AACE;EACE,yBAAA;AAEJ;;AAAE;EACE,yBAAA;AAGJ;;AADE;EACE,yBAAA;AAIJ;;AAFE;EACE,oCAAA;AAKJ;;AAFE;EACE,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;EACA,UAAA;EACA,wBAAA;AAKJ;;AADE;EACE,mBAAA;EACA,UAAA;AAIJ","sourcesContent":[".legend {\n    display: flex;\n    gap: 20px;\n    margin-top: 20px;\n  }\n  \n  .legend-item {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    position: relative;\n  }\n  \n  .legend-color {\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    margin-right: 10px;\n  }\n  \n  /* Define colors for each status */\n  .completed {\n    background-color: #3E7D3E;\n  }\n  .scheduled {\n    background-color: #672b88;\n  }\n  .cancelled {\n    background-color: #F5222D;\n  }\n  .checkIn {\n    background-color: #E8A84C;\n  }\n  .past {\n    background-color: rgba(0,0,0,0.4);\n  }\n  \n  .tooltip {\n    visibility: hidden;\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n    text-wrap: nowrap;\n    padding: 5px;\n    border-radius: 5px;\n    position: absolute;\n    z-index: 1;\n    bottom: 25px;\n    left: 50%;\n    transform: translateX(-50%);\n    opacity: 0;\n    transition: opacity 0.3s;\n    // white-space: nowrap;\n  }\n  \n  .legend-item:hover .tooltip {\n    visibility: visible;\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
