import appConstants from "../constants/appConstants/appConstants.json";

export const extractConsultationsTypeCounts = (value) => {
    try {
        let visitCount = 0;
        let urgentCount = 0;
        let virtualCount = 0;

        if (value && value[appConstants.VISIT] && value[appConstants.PRIMARY_CARE]) {
            visitCount =
                (value[appConstants.VISIT]?.completed || 0) +
                (value[appConstants.VISIT]?.due || 0) +
                (value[appConstants.PRIMARY_CARE]?.completed || 0) +
                (value[appConstants.PRIMARY_CARE]?.due || 0);
        } else if (value && value[appConstants.VISIT] && !value[appConstants.PRIMARY_CARE]) {
            visitCount =
                (value[appConstants.VISIT]?.completed || 0) +
                (value[appConstants.VISIT]?.due || 0);
        } else if (value && !value[appConstants.VISIT] && value[appConstants.PRIMARY_CARE]) {
            visitCount =
                (value[appConstants.PRIMARY_CARE]?.completed || 0) +
                (value[appConstants.PRIMARY_CARE]?.due || 0);
        }

        if (value && value[appConstants.VIRTUAL] && value[appConstants.VIRTUAL_CARE]) {
            virtualCount =
                (value[appConstants.VIRTUAL]?.completed || 0) +
                (value[appConstants.VIRTUAL]?.due || 0) +
                (value[appConstants.VIRTUAL_CARE]?.completed || 0) +
                (value[appConstants.VIRTUAL_CARE]?.due || 0);
        } else if (value && value[appConstants.VIRTUAL] && !value[appConstants.VIRTUAL_CARE]) {
            virtualCount =
                (value[appConstants.VIRTUAL]?.completed || 0) +
                (value[appConstants.VIRTUAL]?.due || 0);
        } else if (value && !value[appConstants.VIRTUAL] && value[appConstants.VIRTUAL_CARE]) {
            virtualCount =
                (value[appConstants.VIRTUAL_CARE]?.completed || 0) +
                (value[appConstants.VIRTUAL_CARE]?.due || 0);
        }

        if (value && value[appConstants.EMERGENCY] && value[appConstants.URGENT_CARE]) {
            urgentCount =
                (value[appConstants.EMERGENCY]?.completed || 0) +
                (value[appConstants.EMERGENCY]?.due || 0) +
                (value[appConstants.URGENT_CARE]?.completed || 0) +
                (value[appConstants.URGENT_CARE]?.due || 0);
        } else if (value && value[appConstants.EMERGENCY] && !value[appConstants.URGENT_CARE]) {
            urgentCount =
                (value[appConstants.EMERGENCY]?.completed || 0) +
                (value[appConstants.EMERGENCY]?.due || 0);
        } else if (value && !value[appConstants.EMERGENCY] && value[appConstants.URGENT_CARE]) {
            urgentCount =
                (value[appConstants.URGENT_CARE]?.completed || 0) +
                (value[appConstants.URGENT_CARE]?.due || 0);
        }

        return [visitCount, urgentCount, virtualCount];


    } catch (error) {
        console.error("Error in extractConsultationsTypeCounts:", error);
    }
}