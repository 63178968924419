import PropTypes from "prop-types"
import React, { useState } from "react"
import Lottie from "react-lottie"
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import * as animationData from "../../assets/images/lottie/delete.json"
import {
  getGenericCategory,
  getPricingCategory,
  getUserDetails,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import { addNewPricingCategory } from "helpers/api_helper"
import "../../assets/scss/custom/pages/_formModal.scss"
import ReactSelect from "react-select"
import Drawer from "@mui/material/Drawer"
import { showMessage } from "components/Notification"

const AddCategoryModal = ({ open, handleCloseDrawer }) => {
  const dispatch = useDispatch()
  const userDetails = useSelector(getUserDetails)
  const [details, setDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [selectedOption, setSelectedOption] = useState(null)

  const [pricingOption, setPricingOption] = useState(null)

  const selectOptions = [
    { value: "PriceCategories", label: "Pricing Categories" },
    // { value: "generic_pricing", label: "Generic Pricing" },
    // Add more options as needed
  ]

  const selectPricingOption = [
    {
      value: "Lab Pricing",
      label: "Lab Pricing",
    },
    {
      value: "Imaging Pricing",
      label: "Imaging Pricing",
    },
    {
      value: "Procedure Pricing",
      label: "Procedure Pricing",
    },
    {
      value: "Consultation Pricing",
      label: "Consultation Pricing",
    },
  ]

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      categoryName: details.categoryName || "",
      categoryType: details.type || "",
    },
    // validationSchema: Yup.object({
    //   categoryName: Yup.string().required("Please Enter Catgeory Name"),
    // }),

    onSubmit: async values => {
      console.log(values.categoryType)
      if (!selectedOption.value) {
        showMessage({ label: "Please Select Category", status: "info" })
        return
      }
      values.categoryType = selectedOption.value

      if (!pricingOption.value) {
        showMessage({ label: "Please Pricing Name", status: "info" })
        return
      }
      values.categoryType = selectedOption.value
      const obj = {
        vetId: userDetails._id,
        pricingCategoryName: pricingOption.value,
        type: selectedOption.value,
        icon: "",
      }

      console.log(obj)
      setIsLoading(true)
      try {
        let response = {}
        response = await addNewPricingCategory(obj)
        if (selectedOption.value === "PriceCategories") {
          dispatch(getPricingCategory({ vetId: userDetails?._id }))
        } else {
          dispatch(getGenericCategory(userDetails?._id))
          console.log("selectedOption5", selectedOption)
        }

        if (response.status === 1) {
          showMessage({
            label: res.message,
            status: "success",
          })
          handleCloseDrawer()
          dispatch(getPricingCategory())
        }
      } catch (error) {
        handleCloseDrawer()
        showMessage({
          label: error.response.data.message,
          status: "error",
        })
      }
      setIsLoading(false)
    },
  })

  const handleCloseClick = () => {
    validation.resetForm()
    onCloseClick()
  }

  console.log("selectedOption", selectedOption)
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      PaperProps={{ style: { width: "400px" } }}
    >
      <div className="py-3 d-flex px-4 border-bottom justify-content-between align-items-center flex-nowrap">
        <p className="font-size-20 mb-0 font-fontFamily-600">
          Add New Category
        </p>
        <button
          type="button"
          onClick={() => {
            validation.resetForm()
            handleCloseDrawer()
          }}
          className="btn-close"
        ></button>
      </div>

      <div>
        <Form onSubmit={validation.handleSubmit} className="w-auto mt-3 px-4 m-auto">
          <FormGroup className="mb-1" row>
            <Label
              htmlFor="categoryName"
              className="col-form-label text-input-label font-fontFamily-500"
            >
              Category Name
            </Label>
            <div className="col-lg-12">
              <ReactSelect
                name="categoryName"
                options={selectPricingOption}
                value={pricingOption}
                onChange={setPricingOption}
                classNamePrefix="form-react-select"
                className="form-react-select"
              />
            </div>
          </FormGroup>

          <FormGroup className="mb-3" row>
            <Label
              htmlFor="category"
              className="col-form-label text-input-label font-fontFamily-500"
            >
              Type
            </Label>
            <div className="col-lg-12">
              <ReactSelect
                name="categoryType"
                options={selectOptions}
                value={selectedOption}
                onChange={setSelectedOption}
                classNamePrefix="form-react-select"
                className="form-react-select"
              />
            </div>
          </FormGroup>
          <div className="hstack gap-2 mb-0 text-left mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-secondary btn-med btn-lg "
            >
              Add Category
            </button>
          </div>
        </Form>
      </div>
    </Drawer>
  )
}

AddCategoryModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default AddCategoryModal
