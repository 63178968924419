import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"

const NotFound = () => {
  const history = useHistory()

  const goHome = () => {
    history.push("/")
  }

  return (
    // <Container
    //   className="d-flex justify-content-center align-items-center"
    //   style={{ height: "100vh" }}
    // >
    //   <Row>
    //     <Col md={12} className="text-center">
    //       <div className="not-found-container p-4 rounded shadow">
    //         <div className="not-found-icon mb-4">
    //           <i
    //             className="fas fa-exclamation-triangle"
    //             style={{ fontSize: "80px", color: "#007bff" }}
    //           ></i>
    //         </div>
    //         <h1 className="not-found-title mb-3" style={{ fontSize: "36px" }}>
    //           404 - Page Not Found
    //         </h1>
    //         <p className="mb-4" style={{ fontSize: "18px" }}>
    //           Oops! The page you are looking for does not exist.
    //         </p>
    //         <Button variant="primary" onClick={goHome}>
    //           Go Home
    //         </Button>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>

    // <Container
    //   className="d-flex justify-content-center align-items-center"
    //   style={{ height: "100vh" }}
    // >
    //   <Row className="text-center">
    //     <Col md={12}>
    //       <img
    //         src={"/notfound.webp"}
    //         alt="404 Not Found"
    //         style={{ maxWidth: "60%", height: "60%", marginBottom: "20px" }}
    //       />
    //       <h1
    //         className="not-found-title mb-3"
    //         style={{ fontSize: "36px", color: "#007bff" }}
    //       >
    //         Oops! Page Not Found
    //       </h1>
    //       <p className="mb-4" style={{ fontSize: "18px" }}>
    //         The page you're looking for doesn't exist. How about heading back to
    //         the homepage?
    //       </p>
    //       <Button variant="primary" onClick={goHome}>
    //         Go Home
    //       </Button>
    //     </Col>
    //   </Row>
    // </Container>

    <Container
      className="d-flex justify-content-center align-items-center"
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)",
      }}
    >
      <Row className="text-center justify-content-center">
        <Col md={8} lg={5}>
          <div
            className="p-3 rounded-3 shadow-lg"
            style={{
              background: "#ffffff",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img
              src={"/notfound.webp"}
              alt="404 Not Found"
              style={{
                maxWidth: "80%",
                height: "auto",
                marginBottom: "15px",
                borderRadius: "10px",
              }}
            />
            <h1
              className="not-found-title mb-2"
              style={{
                fontSize: "32px",
                color: "#343a40",
                fontWeight: "bold",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Oops! Page Not Found
            </h1>
            <p
              className="mb-3"
              style={{
                fontSize: "16px",
                color: "#6c757d",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              The page you're looking for doesn't exist. How about heading back
              to the homepage?
            </p>
            <Button
              variant="primary"
              onClick={goHome}
              style={{
                padding: "8px 16px",
                fontSize: "15px",
                borderRadius: "25px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Go Home
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound
