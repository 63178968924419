import React, { useEffect, useMemo, useState } from "react"

import { Link, useHistory } from "react-router-dom"

import TableContainer from "../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  OrderId,
  BillingName,
  Total,
  Parent,
  NumberVal,
} from "../Pets/components/PatientsCol"

//redux
import { useSelector, useDispatch } from "react-redux"
import ReactSelect from "react-select"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Input,
} from "reactstrap"
import { deleteInventoryItem, deletePet } from "helpers/api_helper"
import {
  getInventoryItems,
  getInventoryitems,
  getUserDetails,
  setGlobalLoading,
} from "../../store/slices"
import { IconSVG } from "components/Common/IconSvg"
import { modifyInventoryType } from "helpers/utils"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import SearchBarComponent from "components/Common/SearchBar"
function Inventory() {
  //meta title
  document.title = "Inventory"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [showFullResults, setShowFullResults] = useState(false)
  const [selectedInventoryItem, setSelectedInventoryitem] = useState(null)

  const history = useHistory()

  const toggleViewModal = () => setModal1(!modal1)

  const dispatch = useDispatch()

  const userDetails = useSelector(getUserDetails)
  //Function to fetch data and set it to the latest data

  const inventoryData = useSelector(getInventoryitems) || []
  console.log("inventoryItems", inventoryData)

  useEffect(() => {
    async function fetchInventoryData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getInventoryItems({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchInventoryData()
  }, [dispatch])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }

  const onClickDelete = item => {
    setSelectedInventoryitem(item)
    setDeleteModal(true)
  }
  console.log("selectedId", selectedInventoryItem?._id)
  const handleDeleteOrder = async () => {
    // if (order._id) {
    const res = await deleteInventoryItem(selectedInventoryItem._id)
    if (res.status == 1) {
      dispatch(getInventoryItems({ vetId: userDetails._id }))
    }
    setDeleteModal(false)
  }

  const handleOrderClicks = () => {
    history.push("/inventory-items-recieved")
  }

  const filteredData = inventoryData?.map(obj => obj.itemType)
  console.log("filtered data", filteredData)
  const uniqueValues = ["All", ...new Set(filteredData)]
  console.log("unique values", uniqueValues)
  console.log(modifyInventoryType(uniqueValues))

  const Conectration = cell => {
    return cell.value + "mg" ? cell.value + "mg" : ""
  }
  const Price = cell => {
    return "Rs " + cell.value ? "Rs " + cell.value : ""
  }
  const InventoryItemName = cell => {
    return (
      <Link
        to={`/itemDetails/${cell.row?.original?._id}`}
        className="font-fontFamily"
      >
        {cell.value ? cell.value : ""}
      </Link>
    )
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "itemName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return (
            <strong>
              <InventoryItemName {...cellProps} />
            </strong>
          )
        },
      },
      {
        Header: "Code",
        accessor: "itemCode",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "itemType",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        filterable: true,
        Cell: cellProps => {
          return <NumberVal {...cellProps} />
        },
      },
      {
        Header: "Retail Price",
        accessor: "retailPrice",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />
        },
      },
      {
        Header: "Strength",
        accessor: "Strength",
        filterable: true,
        Cell: cellProps => {
          return <Conectration {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(
                    `/edit-inventory-items-recieved/${cellProps.row.original._id}`
                  )
                }}
              >
                <i id={"edittooltip" + cellProps.row.original._id}>
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip placement="top" target={"edittooltip" + cellProps.row.original._id}>
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i id={"deletetooltip" + cellProps.row.original._id}>
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip placement="top" target={"deletetooltip" + cellProps.row.original._id}>
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const [selectedValue, setSelectedValue] = useState({
    value: "All",
    label: "All",
  })
  const handleSelectChange = selectedOption => {
    setSelectedValue(selectedOption)
  }

  // console.log(selectedValue)
  const filterVal = inventoryData?.filter(
    obj => obj.itemType === selectedValue?.value
  )
  console.log(filterVal)

  // useEffect(() => {
  //   filterData()
  // }, [selectedValue])

  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState([])

  useEffect(() => {
    const filtered = inventoryData.filter(
      item =>
        item?.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.category?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredItems(filtered)
  }, [inventoryData, searchTerm])
  console.log("FilteredItems", filteredItems)
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row className="appointment-heading-div mb-4 px-3">
            <Col
              className="d-flex align-items-center justify-content-start gap-2 "
              xs="auto"
            >
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">Inventory</h4>
            </Col>

            <Col
              className="d-flex align-items-center justify-content-between gap-4"
              xs="auto"
            >
              <div>
                <div>
                  <FormGroup className="mb-4" row>
                    <div>
                      <Input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className="form-control"
                        placeholder="Find product"
                        value={searchTerm}
                        style={{
                          width: "444px",
                          border: "none",
                          borderBottom: "1px solid #E8E8E8",
                          borderRadius: "0",
                          paddingLeft: "0",
                          paddingBottom: "0",
                        }}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {searchTerm && (
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "444px",
                          height: filteredItems.length > 0 ? "334px" : "60px",
                          background: "#FFFFFF",
                          zIndex: "1",
                          boxShadow: "0px 18px 30px 16px #00000008",
                          borderRadius: "12px",
                          overflowY: showFullResults ? "scroll" : "hidden",
                        }}
                      >
                        {filteredItems.length > 0 ? (
                          <>
                            {showFullResults ? (
                              <>
                                {filteredItems.map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/itemDetails/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.itemName}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {filteredItems.slice(0, 3).map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/itemDetails/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.itemName}
                                    </Link>
                                  </div>
                                ))}
                                {filteredItems.length > 3 && (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "30px",
                                      marginLeft: "27px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFullResults(true)}
                                  >
                                    <i className="fa fa-search me-1" />
                                    See All items
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              marginTop: "14px",
                              marginLeft: "24px",
                              textAlign: "center",
                            }}
                          >
                            No items found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="px-3">
            <Col xs="12" sm="6" md="4" lg="3">
              <ReactSelect
                options={modifyInventoryType(uniqueValues)}
                onChange={handleSelectChange}
                value={selectedValue}
                classNamePrefix="form-react-select"
                className="form-react-select pb-1"
              />
            </Col>
            <Col
              xs="12"
              sm="6"
              md={{ size: 8, order: "last" }}
              lg={{ size: 9, order: "last" }}
            >
              {/* Buttons */}
              {/* <div className="d-flex justify-content-end align-items-center">
                <Button
                  outline
                  color="secondary"
                  className="mx-2"
                  onClick={() => history.push(`/inventory-items-recieved`)}
                >
                  Add Inventory item
                </Button>
                
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={
                      selectedValue.value === "All" ? inventoryData : filterVal
                    }
                    isGlobalFilter={false}
                    isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    emptyListLink="/inventory-items-recieved"
                    emptyText="No Item found"
                    emptyLinkText="+ Add New Items"
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Inventory
