import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter, useHistory } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { IconSVG } from "components/Common/IconSvg"
import walletIcon from "../../assets/icons/Wallet.png"
import inventoryIcon from "../../assets/icons/InventoryIcon.png"
import petIcon from "../../assets/icons/PetIcon.png"
import Medication from "../../assets/icons/Medication.svg"
//react-redux
import { useSelector } from "react-redux"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        console.log(matchingMenuItem, "mathcing")
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  //Getting toggle sidebar value from redux store

  const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)

  const history = useHistory()

  const handleAppointmentClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/appointments")
    }
  }

  const handlePatientsClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/patients")
    }
  }
  const handleParentsClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/pet-parents")
    }
  }
  const handleImagingClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/imaging-requests")
    }
  }
  const handleLabsClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/lab-requests")
    }
  }
  const handleAdministrationClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/timings")
    }
  }
  const handleInventoryClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/inventory")
    }
  }
  const handleBillingClick = () => {
    if (leftSideBarType === "condensed") {
      history.push("/pricing")
    }
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100 d-print-none" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="has-arrow hide-arrow">
                <IconSVG icon="dashboard" />
                <span className="font-fontFamily">{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li onClick={handleAppointmentClick}>
              <Link to="/appointments" className="has-arrow">
                <IconSVG icon="appointment" />
                <span className="font-fontFamily">
                  {props.t("Appointment")}
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/appointments">
                    <p className="font-1 m-0">
                      {props.t("Appointments")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/add-appointment" style={{ textWrap: "nowrap"}}>
                    <p className="font-1 m-0">
                      {props.t("Add Appointments")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/virtual-appointments">
                    <p className="font-1 m-0">
                      {props.t("Virtual Appointments")}
                    </p>
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/all-appointment">
                  <p className="font-1">{props.t("All Appointments")}</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li onClick={handlePatientsClick}>
              <Link to="/patients" className="has-arrow">
                <img
                  src={petIcon}
                  style={{ marginRight: "18px" }}
                  width="18px"
                  height="18px"
                />
                <span className="font-fontFamily">{props.t("Patients")}</span>
              </Link>

              <ul className="sub-menu">
                {/* <li>
                  <Link to="/add-parent">
                    <p className="font-1 m-0">
                      {props.t("Add Pet Parent")}
                    </p>
                  </Link>
                </li> */}
                <li>
                  <Link to="/add-pet">
                    <p className="font-1 m-0">
                      {props.t("Add Patients")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/patients">
                    <p className="font-1 m-0">
                      {props.t("Patients")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/pet-parents">
                    <p className="font-1 m-0">
                      {props.t("Pet Parents")}
                    </p>
                  </Link>
                </li>
              </ul>
            </li>

            <li onClick={handlePatientsClick}>
              <Link to="/pharmacy" className="has-arrow">
                <img
                  src={Medication}
                  style={{ marginRight: "18px" }}
                  width="18px"
                  height="18px"
                />
                <span className="font-fontFamily">{props.t("Pharmacy")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/pharmacy-request">
                    <p className="font-1 m-0">
                      {props.t("Requests")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/re-issue-pharmacy">
                    <p className="font-1 m-0">
                      {props.t("Re-Issue Pharmacy")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/dispense-pharmacy">
                    <p className="font-1 m-0">
                      {props.t("Dispense Pharmacy")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/add-pharmacy-request">
                    <p className="font-1 m-0">
                      {props.t("Add Request")}
                    </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li onClick={handleInventoryClick}>
              <Link to="/inventory" className="has-arrow">
                <img
                  src={inventoryIcon}
                  style={{ marginRight: "18px" }}
                  width="18px"
                  height="18px"
                />
                <span className="font-fontFamily">{props.t("Inventory")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/inventory">
                    <p className="font-1 m-0">
                      {props.t("Inventory")}
                    </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li onClick={handleImagingClick}>
              <Link to="/imaging" className="has-arrow">
                <IconSVG icon="imaging" />
                <span className="font-fontFamily">{props.t("Imaging")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/imaging-requests">
                    <p className="font-1 m-0">
                      {props.t("Requests")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/imaging-completed">
                    <p className="font-1 m-0">
                      {props.t("Completed")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/add-imaging-request">
                    <p className="font-1 m-0">
                      {props.t("Add Request")}
                    </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li onClick={handleLabsClick}>
              <Link to="/labs" className="has-arrow">
                <IconSVG icon="labs" />
                <span className="font-fontFamily">{props.t("Labs")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/lab-requests">
                    <p className="font-1 m-0">
                      {props.t("Requests")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/labs-completed">
                    <p className="font-1 m-0">
                      {props.t("Completed")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/add-lab-request">
                    <p className="font-1 m-0">
                      {props.t("Add Request")}
                    </p>
                  </Link>
                </li>
              </ul>
            </li>

            <li onClick={handleBillingClick}>
              <Link to="/pricing" className="has-arrow">
                <img
                  src={walletIcon}
                  style={{ marginRight: "18px" }}
                  width="18px"
                  height="18px"
                />
                <span className="font-fontFamily">{props.t("Billing")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/pricing">
                    <p className="font-1 m-0">
                      {props.t("Pricing")}
                    </p>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/invoices">{props.t("Invoices")}</Link>
                </li> */}
              </ul>
            </li>
            <li onClick={handleAdministrationClick}>
              <Link to="/timings" className="has-arrow">
                <IconSVG icon="settings" />
                <span className="font-fontFamily">
                  {props.t("Administration")}
                </span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/timings">
                    <p className="font-1 m-0">
                      {props.t("Operating Hours")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/pricing-Category">
                    <p className="font-1 m-0">
                      {props.t("Pricing Category")}
                    </p>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-inbox">{props.t("Inbox")}</Link>
                </li>
                <li>
                  <Link to="/email-read">{props.t("Read Email")} </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <span key="t-email-templates">{props.t("Templates")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/email-template-basic">
                        {props.t("Basic Action")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/email-template-alert">
                        {props.t("Alert Email")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/email-template-billing">
                        {props.t("Billing Email")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Invoices")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/invoices-list">{props.t("Invoice List")}</Link>
                </li>
                <li>
                  <Link to="/invoices-detail">{props.t("Invoice Detail")}</Link>
                </li>
              </ul>
            </li> */}

            <ul>
              <li className="menu-title">Pages</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user-circle"></i>
                  <span className="font-fontFamily">
                    {props.t("Authentication")}
                  </span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/pages-login">{props.t("Login")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-login-2">{props.t("Login 2")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-register">{props.t("Register")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-register-2">{props.t("Register 2")}</Link>
                  </li>
                  <li>
                    <Link to="/page-recoverpw">
                      {props.t("Recover Password")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/page-recoverpw-2">
                      {props.t("Recover Password 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-lock-screen">{props.t("Lock Screen")}</Link>
                  </li>
                  <li>
                    <Link to="/auth-lock-screen-2">
                      {props.t("Lock Screen 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/page-confirm-mail">
                      {props.t("Confirm Mail")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/page-confirm-mail-2">
                      {props.t("Confirm Mail 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-email-verification">
                      {props.t("Email Verification")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-email-verification-2">
                      {props.t("Email Verification 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-two-step-verification">
                      {props.t("Two Step Verification")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-two-step-verification-2">
                      {props.t("Two Step Verification 2")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-file"></i>
                  <span className="font-fontFamily">{props.t("Utility")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/pages-starter">{props.t("Starter Page")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-maintenance">
                      {props.t("Maintenance")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages-comingsoon">{props.t("Coming Soon")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-timeline">{props.t("Timeline")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-faqs">{props.t("FAQs")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-pricing">{props.t("Pricing")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-404">{props.t("Error 404")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-500">{props.t("Error 500")}</Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{props.t("Components")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-tone" />
                  <span>{props.t("UI Elements")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-cards">{props.t("Cards")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-grid">{props.t("Grid")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-images">{props.t("Images")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-modals">{props.t("Modals")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-offcanvas">{props.t("OffCanvas")}</Link>
                  </li>

                  <li>
                    <Link to="/ui-rangeslider">{props.t("Range Slider")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-session-timeout">
                      {props.t("Session Timeout")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-progressbars">
                      {props.t("Progress Bars")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-placeholders">{props.t("Placeholders")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/ui-sweet-alert">
                      {props.t("Sweet-Alert")}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/ui-tabs-accordions">
                      {props.t("Tabs & Accordions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-typography">{props.t("Typography")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-toasts">{props.t("Toasts")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-video">{props.t("Video")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-general">{props.t("General")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-colors">{props.t("Colors")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-rating">{props.t("Rating")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-notifications">
                      {props.t("Notifications")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-utilities">
                      <span className="badge rounded-pill bg-success float-end">
                        {props.t("New")}
                      </span>
                      {props.t("Utilities")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#">
                  <i className="bx bxs-eraser"></i>
                  <span className="badge rounded-pill bg-danger float-end">
                    10
                  </span>
                  <span>{props.t("Forms")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/form-elements">{props.t("Form Elements")}</Link>
                  </li>
                  <li>
                    <Link to="/form-layouts">{props.t("Form Layouts")}</Link>
                  </li>
                  <li>
                    <Link to="/form-validation">
                      {props.t("Form Validation")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                  </li>
                  <li>
                    <Link to="/form-editors">{props.t("Form Editors")}</Link>
                  </li>
                  <li>
                    <Link to="/form-uploads">
                      {props.t("Form File Upload")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-xeditable">
                      {props.t("Form Xeditable")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-repeater">{props.t("Form Repeater")}</Link>
                  </li>
                  <li>
                    <Link to="/form-wizard">{props.t("Form Wizard")}</Link>
                  </li>
                  <li>
                    <Link to="/form-mask">{props.t("Form Mask")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Tables")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/tables-basic">{props.t("Basic Tables")}</Link>
                  </li>
                  <li>
                    <Link to="/tables-datatable">{props.t("Data Tables")}</Link>
                  </li>
                  <li>
                    <Link to="/tables-responsive">
                      {props.t("Responsive Table")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tables-editable">
                      {props.t("Editable Table")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tables-dragndrop">
                      {props.t("Drag & Drop Table")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bxs-bar-chart-alt-2"></i>
                  <span>{props.t("Charts")}</span>
                </Link>

                <ul className="sub-menu">
                  <li>
                    <Link to="/apex-charts">{props.t("Apex charts")}</Link>
                  </li>
                  <li>
                    <Link to="/e-charts">{props.t("E Chart")}</Link>
                  </li>
                  <li>
                    <Link to="/chartjs-charts">{props.t("Chartjs Chart")}</Link>
                  </li>
                  <li>
                    <Link to="/chartist-charts">
                      {props.t("Chartist Chart")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/charts-knob">{props.t("Knob Charts")}</Link>
                  </li>
                  <li>
                    <Link to="/sparkline-charts">
                      {props.t("Sparkline Chart")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/re-charts">{props.t("Re Chart")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-aperture"></i>
                  <span>{props.t("Icons")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/icons-boxicons">{props.t("Boxicons")}</Link>
                  </li>
                  <li>
                    <Link to="/icons-materialdesign">
                      {props.t("Material Design")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                  </li>
                  <li>
                    <Link to="/icons-fontawesome">
                      {props.t("Font awesome")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-map"></i>
                  <span>{props.t("Maps")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/maps-google">{props.t("Google Maps")}</Link>
                  </li>
                  <li>
                    <Link to="/maps-vector">{props.t("Vector Maps")}</Link>
                  </li>
                  <li>
                    <Link to="/maps-leaflet">{props.t("Leaflet Maps")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-share-alt"></i>
                  <span>{props.t("Multi Level")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/#">{props.t("Level 1.1")}</Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      {props.t("Level 1.2")}
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/#">{props.t("Level 2.1")}</Link>
                      </li>
                      <li>
                        <Link to="/#">{props.t("Level 2.2")}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
