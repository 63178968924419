import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { isEmpty, map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Image
import logo from "../../../assets/images/supaw-vets.svg"
import { fetchAppointmentsBasedOnId } from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import { useDispatch } from "react-redux"
import { setGlobalLoading } from "store/slices"
import moment from "moment"

import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import { calculateAge, capitalizeFirstLetter } from "helpers/commonHelpers"

const InvoiceDetail = props => {
  const [details, setDetails] = useState({})
  const [noPrescriptionFound, setPrescriptionFound] = useState(false)
  console.log("Details", details)
  //meta title
  document.title = "Prescription Detail | Supaw"

  const {
    match: { params },
  } = props
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      document.body.classList.add("prescription-container")
      if (params && params.id) {
        dispatch(setGlobalLoading(true))
        const _details = await fetchAppointmentsBasedOnId(params.id)
        if (_details.status === 1 && _details.data) {
          setDetails(_details.data)
          dispatch(setGlobalLoading(false))
        } else {
          setPrescriptionFound(true)
          dispatch(setGlobalLoading(false))
        }
      }
    }
    fetchData()
  }, [params])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  const _specialization =
    typeof details?.doctorId?.specialization === "object"
      ? details?.doctorId?.specialization?.map(el => el.label).join(", ")
      : details?.doctorId?.specialization
  const currentDate = new Date()
  const formattedDate = `${currentDate.getDate().toString().padStart(2, "0")}${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${currentDate.getFullYear()}`
  

    const handleDownloadPDF = () => {
      const content = document.querySelector(".download-pdf-content");
    
      // Temporarily apply full-width styles
      content.style.width = "100%";
      content.classList.add("fs-3", "text-dark");
    
      const termsConditions = content.querySelector(".authorized-signatory ol");
      if (termsConditions) {
        termsConditions.style.fontSize = "15px";
        termsConditions.style.lineHeight = "1.6";
        termsConditions.style.color = "#000";
      }
    
      const tableCells = content.querySelectorAll("td");
      tableCells.forEach(cell => {
        cell.style.fontSize = "15px";
        cell.style.lineHeight = "1.6";
      });
    
      const logo = content.querySelector(".logo");
      if (logo) {
        logo.style.height = "40px";
        logo.style.width = "auto";
      }
    
      const heads = content.querySelectorAll(".head");
      heads.forEach(head => {
        head.style.fontSize = "15px";
      });
    
      const headsData = content.querySelectorAll(".head-data");
      headsData.forEach(headData => {
        headData.style.fontSize = "15px";
      });
    
      dispatch(setGlobalLoading(true));
    
      const scale = 3; // Higher scale for improved resolution
      const padding = 5;
    
      html2canvas(content, {
        scale: scale,
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY,
        width: content.scrollWidth,
      }).then(canvas => {
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
    
        const imgWidth = pdfWidth - padding * 2;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
        let heightLeft = imgHeight;
        let position = 0;
    
        const imgData = canvas.toDataURL("image/png");
    
        // Split content across multiple pages if necessary
        while (heightLeft > 0) {
          pdf.addImage(
            imgData,
            "PNG",
            padding,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          heightLeft -= pdfHeight - padding * 2;
          position -= pdfHeight;  // Move to next page section
    
          if (heightLeft > 0) pdf.addPage(); // Add new page if more content remains
        }
    
        pdf.save(`Prescription-${details?.petObjectId?.petId}-${formattedDate}.pdf`);
        dispatch(setGlobalLoading(false));
    
        // Reset styles after PDF is saved
        content.style.width = "";
        content.classList.remove("fs-3", "text-dark");
        if (termsConditions) {
          termsConditions.style.fontSize = "";
          termsConditions.style.lineHeight = "";
          termsConditions.style.color = "";
        }
        tableCells.forEach(cell => {
          cell.style.fontSize = "";
          cell.style.lineHeight = "";
        });
        if (logo) {
          logo.style.height = "";
          logo.style.width = "";
        }
      });
    };
    
    

  return (
    <React.Fragment>
      <div className="page-content prescription-container">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Prescription: Detail" />
          {!isEmpty(details) || !noPrescriptionFound ? (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="download-pdf-content">
                      <div className="invoice-title">
                        <h4 className="float-end font-size-20">
                          Pet Id:{" "}
                          <span className="text-primary">
                            {details?.petObjectId?.petId}
                          </span>
                        </h4>
                        <div>
                          <div className="prescription-doc-details">
                            <div className="d-flex align-items-center gap-2">
                              <img
                                className="logo"
                                src={details?.doctorId?.profile}
                                alt="logo"
                                height="50"
                              />
                              <h4 className="font-size-12 fw-bold">
                                {details?.doctorId?.clinicName.split(" ").map((text) => capitalizeFirstLetter(text)).join(" ")}
                              </h4>
                            </div>
                            {/* <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.fullName}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.clinicName}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.address}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.email}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              +91-{details?.doctorId?.phone}
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col sm="2">
                          <span>
                            {" "}
                            <h3 className="font-size-12 fw-bold head">
                              Invoice To
                            </h3>{" "}
                            <p className="head-data">
                             {details?.parentId?.fullName || ""}
                            </p>
                            {/* {details?.petObjectId?.fullName} */}
                          </span>
                        </Col>
                        <Col sm="2">
                          <span>
                            {" "}
                            <h3 className="font-size-12 fw-bold head">
                              Pet Name
                            </h3>{" "}
                            <p className="head-data">
                             {details?.petObjectId?.fullName || ""}
                            </p>
                            {/* {details?.petObjectId?.fullName} */}
                          </span>
                        </Col>
                         <Col sm="2">
                          <span>
                            {" "}
                            <h3 className="font-size-12 fw-bold head">
                              Patient Type
                            </h3>{" "}
                            <p className="head-data">
                             {details?.petObjectId?.petType}
                            </p>
                          </span>
                        </Col>
                     
                         {/* calculateAge */}
                         <Col sm="2">
                          <span>
                            {" "}
                            <h3 className="font-size-12 fw-bold head">
                              Patient Age
                            </h3>{" "}
                            <p className="head-data">
                              {details?.petObjectId?.dateOfBirth ? calculateAge(details?.petObjectId?.dateOfBirth) : "N/A"}
                            </p>
                            {/* {details?.petObjectId?.petType}(
                            {details?.petObjectId?.petBreed}) */}
                          </span>
                        </Col>
                        <Col sm="2">
                          <span>
                            {" "}
                            <h3 className="font-size-12 fw-bold head">
                              Admission Date
                            </h3>{" "}
                            <p className="head-data">
                              {details?.date ? getDateDDMMYYYY(details?.date) : "N/A"}
                            </p>
                            {/* {details?.parentId?.fullName} */}
                          </span>
                        </Col>
                        <Col sm="2">
                          <span>
                            {" "}
                            <h3 className="font-size-12 fw-bold head">
                              Appointment Type
                            </h3>{" "}
                            <p className="head-data">
                              {details?.visitType || "N/A"}
                            </p>
                            {/* {details?.petObjectId?.petType}(
                            {details?.petObjectId?.petBreed}) */}
                          </span>
                        </Col>
                       
                        {/* <Col sm="6" className="text-sm-end">
                          <address>
                            <strong>Date: </strong>
                            {getDateDDMMYYYY(details?.date)}
                          </address>
                        </Col> */}
                      </Row>
                      {details?.medicines?.length && (
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-12 fw-bold">Medicines</h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th style={{ width: "70px" }} className="text-left head">No.</th>
                                  <th className="text-left head">Medicine Name</th>
                                  <th className="text-left head">Dosage</th>
                                  <th className="text-left head">Duration</th>
                                  <th className="text-left head">Instructions</th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {details?.medicines?.length
                                  ? details?.medicines?.map((item, index) => (
                                      <tr key={index}>
                                        <td className="font-size-14-black">
                                          {index + 1}
                                        </td>
                                        <td>
                                          {/* <div className="font-size-14-black">
                                            {item.medicine}
                                          </div> */}
                                          <div>
                                            {item.medicine} {<br />}
                                            {details?.petObjectId?.fullName}(
                                            {details.petObjectId.petBreed}/
                                            {details.petObjectId.gender}/
                                            {details.petObjectId.colour})
                                          </div>
                                        </td>
                                        <td className="font-size-14-black">
                                          {item.dosage}
                                        </td>
                                        <td className="font-size-14-black">
                                          {item.duration}
                                        </td>
                                        <td className="text-left font-size-14-black">
                                          {item?.instructions || "N/A"}
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}

                      {details?.vaccinations?.length ? (
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-12 fw-bold">Vaccines</h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-left head" style={{ width: "70px" }}>No.</th>
                                  <th className="text-left head">Vaccines Name</th>
                                  <th className="text-left head">Type</th>
                                  <th className="text-left head">Date of Vaccine</th>
                                  <th className="text-left head">Expiry Date</th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {details?.vaccinations?.length
                                  ? details?.vaccinations?.map(
                                      (item, index) => {
                                        console.log(item, "item")
                                        return (
                                          <tr key={index}>
                                            <td className="font-size-14-black">
                                              {index + 1}
                                            </td>
                                            <td className="font-size-14-black">
                                              <div>
                                                {item.vaccine}
                                              </div>
                                              <div>
                                                {details?.petObjectId?.fullName}
                                                ({details.petObjectId.petBreed}/
                                                {details.petObjectId.gender}/
                                                {details.petObjectId.colour})
                                              </div>
                                            </td>
                                            <td className="font-size-14-black">
                                              {item.type}
                                            </td>
                                            <td className="font-size-14-black">
                                              {moment(item.date).format(
                                                "MMM Do YY"
                                              )}
                                            </td>
                                            <td className="text-left head font-size-14-black">
                                              {moment(item.expiryDate).format(
                                                "MMM Do YY"
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      }
                                    )
                                  : null}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : null}

                      {details?.imagingTests?.length ? (
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-12 fw-bold">
                              Imaging Tests
                            </h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-left head" >No.</th>
                                  <th className="text-left head" >Test Name</th>

                                  <th className="text-left head">
                                    Description
                                  </th>
                                  <th className="text-left head" >Instructions</th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {details?.imagingTests?.length
                                  ? details?.imagingTests?.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td className="font-size-14-black">
                                            {index + 1}
                                          </td>
                                          <td>
                                            <div className="font-size-14-black">
                                              {item.type}
                                            </div>
                                            <div>
                                              {details?.petObjectId?.fullName}(
                                              {details.petObjectId.petBreed}/
                                              {details.petObjectId.gender}/
                                              {details.petObjectId.colour})
                                            </div>
                                          </td>
                                          <td className="font-size-14-black">
                                            {item.result}
                                          </td>
                                          <td className="text-left head font-size-14-black">
                                            {item.notes}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  : null}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {details?.labTests?.length ? (
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-12 fw-bold">Lab Tests</h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-left head" >No.</th>
                                  <th className="text-left head" >Test Name</th>

                                  <th className="text-left head" >Description</th>
                                  <th className="text-left head" >Instructions</th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {details?.labTests?.length
                                  ? details?.labTests?.map((item, index) => (
                                      <tr key={index}>
                                        <td className="font-size-14-black">
                                          {index + 1}
                                        </td>
                                        <td>
                                          <div className="font-size-14-black">
                                            {item.title}
                                          </div>
                                          <div>
                                            {details?.petObjectId?.fullName}(
                                            {details.petObjectId.petBreed}/
                                            {details.petObjectId.gender}/
                                            {details.petObjectId.colour})
                                          </div>
                                        </td>
                                        <td className="font-size-14-black">
                                          {item.description}
                                        </td>
                                        <td className="text-left head font-size-14-black">
                                          {item.instructions}
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <Row>
                        {
                          details?.primaryDiagnosis && !!details?.primaryDiagnosis.length && (
                            <Col sm="6">
                              <address>
                                <strong>Primary Diagnosis:</strong>

                                <span className="mx-2">
                                  {details?.primaryDiagnosis
                                    ?.filter(el => el.type === "primary")
                                    .map(el1 => {
                                      return (
                                        <span key={el1.title}>{el1.title}</span>
                                      )
                                    })}
                                </span>
                                <br />
                                <strong>Secondary Diagnosis:</strong>

                                <span>
                                  {details?.primaryDiagnosis
                                    ?.filter(el => el.type === "secondary")
                                    .map(el1 => {
                                      return (
                                        <span key={el1.title}>{el1.title}</span>
                                      )
                                    })}
                                </span>
                              </address>
                            </Col>
                          )
                        }

                        {
                          details?.patientAllergies && !!details?.patientAllergies.length && (
                          <Col sm="6" className="text-sm-end">
                            <address>
                              <strong>Allergy: </strong>
                              <span>
                                {details?.patientAllergies?.map(el1 => {
                                  return <span key={el1.title}>{el1.title}</span>
                                })}
                              </span>
                            </address>
                          </Col>
                          )
                        }
                      </Row>
                      {console.log("Details::::", details)}

                      {
                        details?.notes && (
                          <div className="">
                            <h3 className="font-size-12 fw-bold">Notes: </h3>
                            {details?.notes}
                          </div>
                        )
                      }

                      <Row>
                        <Col sm="12" className="text-sm-end">
                          <div className="authorized-signatory fs-5">
                            <h3 className="text-left font-size-12 fw-bold">
                              Terms & Conditions
                            </h3>
                            <ol className="fs-6 text-left">
                              <li>
                                Medicines/Pharmacy products once purchased,
                                cannot be returned or exchanged.
                              </li>
                              <li>
                                Items once purchased cannot be returned for
                                refund and the returned amount will be provided
                                as Supaw Pet Clinic Credit Note.
                              </li>
                              <li>
                                The returned product should be unused, with
                                original packaging and product tag intact. The
                                product should be in Resellable condition,
                                discretion of which will lie with Supaw Pet
                                Clinic.
                              </li>
                            </ol>
                          </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col sm="12" className="text-sm-end mt-auto pb-2">
                          <div className="authorized-signatory text-end head">
                            Authorised Signatory{" "}
                          </div>
                        </Col>
                      </Row>
                      <div className="text-center">
                        Prescription generated on Supaw.co
                      </div>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={handleDownloadPDF}
                          className="btn me-2"
                          style={{ background: "#E89F23", color: "white" }}
                        >
                          <i className="fa fa-download" />
                        </Link>
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn me-2"
                          style={{ background: "#E75C25", color: "white" }}
                        >
                          <i className="fa fa-print" />
                        </Link>
                        {/* <Link
                          to="#"
                          className="btn w-md "
                          style={{ background: "#E75C25", color: "white" }}
                        >
                          Send
                        </Link> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Card>
                <CardBody>
                  <CardText>No Prescription found</CardText>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
