// import React from "react"
// import { Carousel } from "react-responsive-carousel"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
// import { Col } from "reactstrap"

// const CarouselPage = ({ landingPage, imageTitle }) => {
//   return (
//     <React.Fragment>
//       <Col xl={7} className="position-sticky d-none d-md-block ">
//         <div className="auth-full-bg w-100">
//           <div className="w-100 d-flex flex-column justify-content-center background-image-bottom">
//             <div className="landingPage-text-div">
//               <h3
//                 className="font-fontFamily font-weight-500 fontSize-24"
//                 style={{
//                   color: "#11142D",
//                 }}
//               >
//                 {imageTitle}
//               </h3>
//               <p style={{ color: "#808191" }} className="fontSize-16">
//                 Quickly get a view of all the business
//               </p>
//             </div>
//             <div
//               className="landingPage-img-div"
//               style={{ height: "100vh", overflow: "hidden" }}
//             >
//               <img
//                 src={landingPage}
//                 style={{
//                   width: "100%",
//                   height: "115%",
//                   objectFit: "cover",
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       </Col>
//     </React.Fragment>
//   )
// }
// export default CarouselPage

import React from "react"
import { Col } from "reactstrap"

const CarouselPage = ({ landingPage, imageTitle }) => {
  return (
    <React.Fragment>
      <Col
        xl={7}
        className=" d-none d-md-block "
        style={{
          height: "100vh",
        }}
      >
        <div className="auth-full-bg w-100 h-100">
          <div className="w-100 h-100 d-flex flex-column justify-content-center background-image-bottom">
            <div className="landingPage-text-div">
              <h3
                className="font-fontFamily font-weight-500 fontSize-24"
                style={{ color: "#11142D" }}
              >
                {imageTitle}
              </h3>
              <p style={{ color: "#808191" }} className="fontSize-16">
                Quickly get a view of all the business
              </p>
            </div>
            <div
              className="landingPage-img-div"
              style={{
                height:
                  "100vh" /* Ensure the height covers the viewport height */,
                width: "100%" /* Ensure the width covers the full width */,
                overflow: "hidden" /* Hide any overflow */,
                position: "relative",
              }}
            >
              <img
                src={landingPage}
                style={{
                  position: "absolute",
                  right: "-14%",
                  width: "100%",
                  height: "115%",
                  maxWidth:
                    "2560px" /* Prevent image from exceeding 2560 pixels width */,
                  objectFit: "scale-down" /* Maintain aspect ratio */,
                }}
                alt={imageTitle}
              />
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default CarouselPage
