import { IconSVG } from 'components/Common/IconSvg'
import React from 'react'
import { Link } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'

const ActionButton = ({cellProps, type}) => {
    return (
        <div>
            <Link
                to={`/edit-lab-request/${cellProps?.row.original._id}?type=${type}`}
                className="text-success"
            >
                <i id={"edittooltip" + cellProps?.row.original._id + type}>
                    <IconSVG icon={type} />
                </i>
                <UncontrolledTooltip placement="top" target={"edittooltip" + cellProps.row.original._id + type}>
                    {type}
                </UncontrolledTooltip>
            </Link>
        </div>
    )
}

export default ActionButton