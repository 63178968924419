import React from "react"
import { Doughnut } from "react-chartjs-2"
import "./style/dashboard.scss"

export const DonutChart = ({ title, data, labels, colors, text, genderDistribution }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows responsive height adjustment
    plugins: {
      legend: { display: false }, // Hide legend inside the chart
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const maleCount = genderDistribution?.[tooltipItem.dataIndex]?.Male || 0;
            const femaleCount = genderDistribution?.[tooltipItem.dataIndex]?.Female || 0;
            return `${maleCount} Male, ${femaleCount} Female`;
          },
        },
      },
    },
    cutout: "80%", // Adjust the inner cutout for the donut effect
  }

  return (
    <div
      className=" custom-card  card chart-card dashboard-card"
      style={{ borderRadius: "15px" }}
    >
      <div className="card-body text-center">
        <h5 className="card-title">{title}</h5>
        <div style={{ height: "250px" }}>
          <Doughnut data={chartData} options={options} />
        </div>
        <div className="centered-text">
          <h3>{data.reduce((a, b) => a + b, 0)}</h3>
          <p>{text}</p>
        </div>
        <div
          className="legend"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "10px",          }}
        >
          {labels.map((label, index) => (
            <span
              key={index}
              className="legend-item"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <span
                className="bullet"
                style={{
                  backgroundColor: colors[index],
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  marginRight: "5px",
                  display: "inline-block",
                }}
              ></span>
              {label}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
