import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import landingImage from "../../assets/images/auth/Labs_landingPage.png"
// import images
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import { useDispatch } from "react-redux"
import { sendForgotPassword } from "helpers/api_helper"
import ButtonMain from "components/Common/Button"

const ForgetPassword2 = () => {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const history = useHistory()
  const dispatch = useDispatch()
  //meta title
  document.title = "Forget Password  | Supaw"

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await sendForgotPassword({ ...values, role: "vet" })
        if (response.status == 1) {
          setSuccess("Please check your email, we have sent you an email")
          setTimeout(() => {
            history.push("/change-password")
          }, 1000)
        }
      } catch (error) {
        let message = error?.response?.data?.message
        if (message && typeof message !== "string") {
          message = "something went wrong!"
        }
        setError(message)
        setIsLoading(false)
      }
    },
  })
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4 vh-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 onboarding-logo-container">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" className="auth-logo-dark" />
                        <img
                          src={logolight}
                          alt=""
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div style={{ padding: "20% 20%" }}>
                      <div>
                        {/* <h5>Reset Password</h5> */}
                        <h1>Reset Password</h1>
                        <p className="text-muted">
                          Send a link to your email to reset your password
                        </p>
                      </div>

                      <div className="mt-4">
                        {false && (
                          <div
                            className="alert alert-success text-center mb-4"
                            role="alert"
                          >
                            Enter your Email and instructions will be sent to
                            you!
                          </div>
                        )}

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          {success ? (
                            <Alert color="success">{success}</Alert>
                          ) : null}
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3">
                            <ButtonMain
                              className="btn btn-primary btn-block"
                              type="submit"
                              isLoading={isLoading}
                            >
                              Reset
                            </ButtonMain>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage
              landingPage={landingImage}
              imageTitle="Connected labs"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPassword2
