import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/dashboard.scss"
import { useSelector } from "react-redux";
import { getDashboardData } from "store/slices";
import { useHistory } from "react-router-dom";

const ScheduleBlock = ({ color, status, appointmentStatus, time, day, handleBookAppointment }) => {
  let newDay = day.split("/").reverse().join("-");
  newDay = new Date(newDay);
  const [hour, minute] = time.split(":").map(Number);
  newDay.setHours(hour)
  newDay.setMinutes(minute);
  newDay.setSeconds(0);
  return (
  <div className="tooltip-container" onClick={(new Date(newDay) >= new Date()) && status === "AVAILABLE" ? () => handleBookAppointment(newDay) : () => {}}>
    <div
      style={{
        width: "40px",
        height: "20px",
        backgroundColor: color,
        borderRadius: "4px",
        margin: "0 2px",
      }}
    ></div>
    <span className="tooltip">
      <b>
        {status === "BLOCKED" ? appointmentStatus.toUpperCase() : new Date(newDay) < new Date() ? "UNAVAILABLE" : status.toUpperCase()} <br/>
          Date: {day} <br/>
          Slot: {time}
      </b>
    </span>
  </div>
)};

const handleSlotsColor = (slotStatus, appointmentStatus, day, time) => {
  let appointmentDay = new Date(day);
  const [hour, minute] = time.split(":").map(Number);
  appointmentDay.setHours(hour)
  appointmentDay.setMinutes(minute);
  appointmentDay.setSeconds(0);
  if(new Date(appointmentDay)  < new Date()){
    return "#E4E4E5";
  }

  switch(slotStatus) {
    case "UNAVAILABLE":
      return "#E4E4E5";
    case "BLOCKED":
      return handleAppointmentSlotsColor(appointmentStatus);
    case "AVAILABLE":
      return "#652B88";
    default:
      return "#ff0000"; 
  }
}

const handleAppointmentSlotsColor = (appointmentStatus) => {
  switch (appointmentStatus) {
    case "cancelled":
      return "#ff0000";
    case "scheduled":
      return "#E89F23";
    case "rescheduled":
      return "#CC7722";
    case "checkIn":
      return "#5D3FD3";
    case "completed":
      return "#009E60";
    default:
      return "red";
  }

}

const DoctorSchedule = ({ title, appointments }) => {

  const [timeSlots, setTimeSlots] = useState([]);

  const dashboardStoreData = useSelector(getDashboardData);

  const history = useHistory();

  useEffect(()=> {

    if(dashboardStoreData?.doctorsAppointment?.length){
      let maxIndex = 0;
        for(let i = 0; i< dashboardStoreData?.doctorsAppointment?.length; i++) {
          if(dashboardStoreData?.doctorsAppointment[i].slots.length > dashboardStoreData?.doctorsAppointment[maxIndex].slots.length) {
            maxIndex = i;
          }
        }
        setTimeSlots(dashboardStoreData?.doctorsAppointment[maxIndex].slots.map(slot => slot.time));
      }

  }, [dashboardStoreData]);

  const handleBookAppointment = (date) => {
    history.push(`/add-appointment?date=${new Date(date).getTime()}`)
  }

  return (
    <div
      className="card doctor-schedule"
      style={{
        height: "400px",
        padding: "10px",
        borderRadius: "12px"
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3" style={{ marginLeft: "2rem", marginTop: "1.5rem"}}>
        <h5 className="card-title">{title}</h5>
        <div>
          <button className="btn btn-link p-0">
            <i className="bi bi-chevron-left"></i>
          </button>
          <button className="btn btn-link p-0">
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>

      <div style={{ overflowY: "auto"}}>
        <table style={{ width: "100%", tableLayout: "auto", borderCollapse: "collapse" }}>
          {/* Time Slots Header */}
          <thead>
            <tr>
              {["Date", ...timeSlots].map((time, index) => (
                <th key={index} style={{ width: index === 0 ? "74px" : "53px", textAlign: "center" }}>
                  {time}
                </th>
              ))}
            </tr>
          </thead>

          {/* Doctor Schedule Rows */}
          <tbody>
            {appointments?.map((appointment, index) => {          
              
              const appointmentSlots = [{}, ...appointment?.slots];
              if(appointment?.slots.length < timeSlots.length){
                  const remainingTimeSlots = timeSlots.slice(-(timeSlots.length - appointment?.slots.length));
                  
                  for(let i =0; i<remainingTimeSlots.length; i++) {
                    appointmentSlots.push({ 
                          time: remainingTimeSlots[i], 
                          status: "UNAVAILABLE", 
                          appointmentStatus: null
                    });
                  }
              }

              return (
              <tr key={index}>
                {appointmentSlots.map((slot, i) => (
                  <td key={i} style={{ height: "20px", whiteSpace: "nowrap", textAlign: 'center' }}>
                    { i === 0 
                    ? new Date(appointment?.day)?.toLocaleDateString("in") 
                    : <ScheduleBlock 
                          color={handleSlotsColor(slot.status, slot.appointmentStatus, appointment?.day, slot.time)}  
                          status={slot.status} appointmentStatus={slot.appointmentStatus}
                          time={slot.time}
                          day={new Date(appointment?.day)?.toLocaleDateString("in")}
                          handleBookAppointment={handleBookAppointment}
                      />
                    }
                  </td>
                ))}
              </tr>
            )})}
          </tbody>
        </table>

        {/* Doctor Schedule Rows
        {doctors.map((doctor, index) => (
          <div key={index} className="d-flex align-items-center mb-2 pb-2">
            <div style={{ width: "80px" }}>{doctor.name}</div>
            {doctor.schedule.map((status, i) => (
              <ScheduleBlock key={i} status={status} />
            ))}
          </div>
        ))} */}
      </div>
    </div>
  )
}

export default DoctorSchedule
