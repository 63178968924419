import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { Col, Container, Row, UncontrolledTooltip } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import TableContainer from "../../components/Common/TableContainer"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//import Images

import DeleteModal from "./DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import {
  getTodaysAppointments,
  fetchAppointmentTypes,
  getUserDetails,
  getAppointmentsByDocId,
  getAppointmentsOfDoc,
  setGlobalLoading,
} from "store/slices"
import { getAppointmentsByWeek, modifyEventsData } from "helpers/utils"
import {
  BillingName,
  NumberVal,
  OrderId,
  Time,
  Total,
} from "pages/Pets/components/PatientsCol"
import { deleteAppointment } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
//calender buttons css
import "../../assets/scss/custom/components/_calender.scss"
import { Date, Parent } from "pages/Parents/components/PatientsCol"
import LeftArrow from "../../assets/icons/ArrowLeft.png"

const AllAppointments = props => {
  //meta title
  document.title = "Appointments | Supaw"

  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [currentSelected, setCurrentSelected] = useState(-1)

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new Appointment
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      //   toggle()
    },
  })

  const appointments = useSelector(getAppointmentsOfDoc) || []
  const moifiedEvents = modifyEventsData(appointments)
  console.log(moifiedEvents)
  const modifiedEventsByDay = getAppointmentsByWeek(appointments)
  console.log(modifiedEventsByDay)
  const userDetails = useSelector(getUserDetails)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleting, setIsdeleting] = useState(false)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchAppointmentTypes())
  }, [dispatch])

  useEffect(() => {
    async function fetchData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getAppointmentsByDocId(userDetails?._id))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchData()
  }, [dispatch])

  const handleDateClick = arg => {}

  /**
   * Handling click on event on calendar
   */
  const handleDeleteEvent = async () => {
    try {
      setIsdeleting(true)
      const res = await deleteAppointment(currentSelected)
      if (res.status == 1) {
        await dispatch(getAppointmentsByDocId(userDetails?._id))
      }
      setIsdeleting(false)
      setDeleteModal(false)
    } catch (error) {
      setIsdeleting(false)
      setDeleteModal(false)
    }
  }

  console.log(appointments, "appointmentsappointments")

  /**
   * On delete event
   */
  const handleEventClick = arg => {
    history.push(`/edit-appointment/${arg.event._def.publicId}`)
  }

  const onClickDelete = arg => {
    // dispatch(onDeleteEvent(event))
    setCurrentSelected(arg._id)
    setDeleteModal(true)
    // toggle()
  }
  // const Parent = cell => {
  //   return (
  //     <Link to={`/parent/${cell.value}`}>{cell.value ? cell.value : ""}</Link>
  //   )
  // }

  const PatientName = cell => {
    return (
      <Link
        to={`/pet/${cell.row?.original?.petObjectId._id}`}
        className="font-fontFamily"
      >
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const DateModified = cell => {
    // if (cell.value) {
    //   const datePart = cell.value.split("T")[0]
    //   return datePart
    // }
    // return ""
    //xpecto Devloper 13/8/2024
    if (cell.value) {
      console.log("cell.value????", cell.value)

      // Split the date and time parts
      const datePart = cell.value.split("T")[0]

      // Split the date into components
      const [year, month, day] = datePart.split("-")

      // Reformat to dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`

      return formattedDate
    }
    return ""
    //xpecto Devloper 13/8/2024
  }
  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }

  const columns = useMemo(
    () => [
      {
        Header: "Pets Name",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <PatientName {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Breed",
        accessor: "petObjectId.petBreed",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Type",
        accessor: "petObjectId.petType",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Parent",
        accessor: "parentId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return <DateModified {...cellProps} />
        },
      },
      {
        Header: "Booked At",
        accessor: "time",
        filterable: true,
        Cell: cellProps => {
          return <Time {...cellProps} />
        },
      },
      {
        Header: "Visit Type",
        accessor: "visitType",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Visits",
        accessor: "visits",
        filterable: true,
        Cell: cellProps => {
          return <NumberVal {...cellProps} />
        },
      },
      {
        Header: "Cost",
        accessor: "cost",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              {cellProps.row.original.status === "scheduled" &&
              cellProps.row.original.status !== "completed" ? (
                <Link
                  to={`/edit-appointment/${cellProps.row.original._id}`}
                  className="text-info"
                >
                  <i id="edit1tooltip">
                    <IconSVG icon="edit" />
                  </i>
                  <UncontrolledTooltip placement="top" target="edit1tooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : null}
              {cellProps.row.original.status === "completed" && (
                <Link
                  to={`/prescription-details/${cellProps.row.original._id}`}
                  className="text-success"
                >
                  <i id="prec">
                    <IconSVG icon="more" />
                  </i>
                  <UncontrolledTooltip placement="top" target="prec">
                    Prescription
                  </UncontrolledTooltip>
                </Link>
              )}
              {cellProps.row.original.status !== "completed" &&
                cellProps.row.original.status !== "cancelled" && (
                  <Link
                    to={`${
                      cellProps.row.original.status === "checkIn"
                        ? "prescription"
                        : "checkin"
                    }/${cellProps.row.original._id}`}
                    className="text-success"
                  >
                    <i id="edittooltip">
                      <IconSVG icon="checkin" />
                    </i>
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      {cellProps.row.original.status === "checkIn"
                        ? "Prescription"
                        : "Checkin"}
                    </UncontrolledTooltip>
                  </Link>
                )}

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const appointmentData = cellProps.row.original
                  onClickDelete(appointmentData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  //Filter todays appoitment data

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row className=" mb-4 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                All Appointments
              </h4>
            </Col>

            <Col className="d-flex justify-content-end mt-4">
              <div className="create-link">
                <Link
                  color="primary"
                  className="font-16 btn-block font-fontFamily"
                  to={"/add-appointment"}
                >
                  <i className="mdi mdi-plus-circle-outline me-1 mr-2" />
                  Create New Appointment
                </Link>
              </div>
            </Col>
          </Row>
          {/* <div className="appointment-heading-div"><h4 className="mb-sm-0 font-size-18"><img
                src={LeftArrow}
                width={25}
                onClick={() => history.goBack()}
                role="button"
              />All Appointments</h4>
          <div className="create-link">
                <Link
                  color="primary"
                  className="font-16 btn-block"
                  to={"/add-appointment"}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Appointment
                </Link>
              </div>
          </div> */}
          <Row>
            <Col className="col-12">
              {/* <div className="create-link">
                <Link
                  color="primary"
                  className="font-16 btn-block"
                  to={"/add-appointment"}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Appointment
                </Link>
              </div> */}
              {/* <Row style={displayCalender}>
                <Col className="col-lg-12">
                  <FullCalendar
                  className="custom-header"
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    slotDuration={"00:30:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "dayGridMonth,dayGridWeek,dayGridDay",
                      right: "prev,next title",
                    }}
                    events={modifiedEventsByDay}
                    selectable={true}
                    dateClick={handleDateClick}
                    eventColor= '#FAFAFA'
                    // eventClick={handleEventClick}
                    eventContent={({ event }) => (
                      <div className="calender-event-container">
                        <div className="calender-total-appointments">{event.title}</div>
                        <div className="calender-appointment-timings-div">
                        {event.extendedProps.times.map((time, index) => (
                          <span key={index} onClick={() => handleNav(event.extendedProps.events[index].id)} className="calender-appointment-timings">
                            {time},
                          </span>
                        ))}
                        </div>
                      </div>
                    )}
                  />
                </Col>
              </Row> */}
              <Row className="mt-20">
                {/* <div className="appointment-header-container">
                  <div className="today-appointment-heading">Today's Appointment</div>
                  <div className="all-apointment-link-div">All Appointments <img src={navigateForword} alt="forword"/></div></div> */}
                <TableContainer
                  columns={columns}
                  // data={appointments || []}
                  data={appointments || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  emptyListLink="/add-appointment"
                  emptyText="No Appointments found"
                  emptyLinkText="+ Add Appointment"
                  customPageSize={10}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AllAppointments.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default AllAppointments
