import React from "react"
import "../../assets/scss/custom.css"
import cakeIcon from "../../assets/images/BirthDayIcon.png"
import { FaBirthdayCake } from "react-icons/fa"

const UpcomingBirthdays = ({ birthdays }) => {
  // const birthdays = [
  //   { name: "Shinzo", date: "05-10-2024" },
  //   { name: "Max", date: "08-10-2024" },
  //   { name: "Bella", date: "09-10-2024" },
  //   // { name: "Bella", date: "09-10-2024" },
  // ]

  return (
    <div className="upcoming-birthdays-card dashboard-card">
      <div className="upcoming-birthdays-header">
        <h5>Upcoming Birthdays</h5>
      </div>
      <div className="upcoming-birthdays-list">
        {birthdays && birthdays.length ? birthdays.map((birthday, index) => {
          let birthdayDate = new Date(birthday.dateOfBirth).setFullYear(new Date().getFullYear());
          const dob = new Date(birthday.dateOfBirth);
          const today = new Date();

          let years = today.getFullYear() - dob.getFullYear();
          
          return (
          <div key={index} className="birthday-item">
            <div className="birthday-info" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              {/* <image src={cakeIcon} height={50} width={20} /> */}
              <FaBirthdayCake className="birthday-icon" size={24} />

              <div style={{ width:"100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h6 className="mb-0">{birthday.fullName}</h6>
                <small className="text-muted">{birthday?.petType || ""}</small>
                <small className="text-muted">{`${years} yrs`}</small>
                <small className="text-muted">{new Date(birthdayDate).toLocaleDateString("in")}</small>
              </div>
              
              <button className="send-wishes-btn" style={{ marginLeft: "4px", position: "relative"}}>🎉 <span className="tooltip-wishes">Send Wishes</span></button>
              
            </div>
          </div>
        )}) : (
        <div className="birthday-item" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
          <div>
            <h6>No birthday data found.</h6>
          </div>
        </div>
        )}
      </div>
      {/* {(birthdays && birthdays.length) ? (<button>see more</button>) : <></>} */}
    </div>
  )
}

export default UpcomingBirthdays
