export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let years = today.getFullYear() - dob.getFullYear();
    let months = today.getMonth() - dob.getMonth();
    let days = today.getDate() - dob.getDate();

    // Adjust the months and years if the birthday hasn't occurred yet this year
    if (months < 0 || (months === 0 && days < 0)) {
        years--;
        months += 12;
    }

    // Adjust the days if the day of the month hasn't occurred yet
    if (days < 0) {
        const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        days += lastMonth.getDate();
        months--;
    }

    return `${years ? years : ""} ${years ? "year" : ""}${years > 1 ? 's' : ''} ${months} month${months > 1 ? 's' : ''}`;
}

export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}