import React from "react"

export default function Stepper({ active }) {
  return (
    // <div className="stepper-container ">
    //   <div className="circle-container d-flex my-1 ">
    //     <Circle number={1} active={active} />
    //     <Circle number={2} active={active} />
    //     <Circle number={3} active={active} />
    //     <Circle number={4} active={active} />
    //   </div>
    //   <Line />
    // </div>

    // <div
    //   className="stepper-container d-flex justify-content-center"
    //   // style={{ width: "auto", marginLeft: "10%", marginRight: "10%" }}
    // >
    //   <div
    //     className="circle-container d-flex my-1 justify-content-between"
    //     style={{ width: "100%" }}
    //   >
    //     <Circle number={1} active={active} />
    //     <Circle number={2} active={active} />
    //     <Circle number={3} active={active} />
    //     <Circle number={4} active={active} />
    //   </div>
    //   <Line />
    // </div>

    <div
      className="stepper-container d-flex justify-content-center"
      style={{
        width: "100%",
        padding: "0 2%", // Adding padding to provide space on smaller screens
        maxWidth: "1200px", // Setting a maximum width to prevent excessive stretching on larger screens
      }}
    >
      <div
        className="circle-container d-flex my-1 justify-content-between"
        style={{
          width: "100%",
          maxWidth: "600px", // Adjust as needed to control the width on smaller screens
        }}
      >
        <Circle number={1} active={active} />
        <Circle number={2} active={active} />
        <Circle number={3} active={active} />
        <Circle number={4} active={active} />
      </div>
      <Line />
    </div>
  )
}

const Circle = ({ number, active }) => {
  return (
    <div className={active === number ? "circle-active" : "circle"}>
      {number}
    </div>
  )
}

const Line = () => {
  return <div className="line"></div>
}
