import { IconSVG } from 'components/Common/IconSvg'
import { getDateDDMMYYYY } from 'helpers/utils'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

const LabRequestViewBody = ({ cellData }) => {
  return (
      <React.Fragment>
          <div
              data-bs-toggle="modal"
              data-bs-target="#lab-request-view"
              className="text-black"
          >
              <i id="viewtooltip">
                  <IconSVG icon="view" />
              </i>

              <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
              </UncontrolledTooltip>
          </div>

          <div className="modal fade" id="lab-request-view" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                      <div className="modal-header bg-light">
                          <h5 className="modal-title" id="exampleModalLabel">
                              {cellData?.petObjectId?.fullName && cellData?.name ? `${cellData?.petObjectId?.fullName} (${cellData?.name})` : ""}
                          </h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <div className="container-fluid data-box">
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Pet name</strong></div>
                                  <div className="col-sm-8">{cellData?.petObjectId?.fullName || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Gender</strong></div>
                                  <div className="col-sm-8">{cellData?.petObjectId?.gender || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Pet parent</strong></div>
                                  <div className="col-sm-8">{cellData?.petObjectId?.ParentName || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Pet parent phone</strong></div>
                                  <div className="col-sm-8">{cellData?.parentId?.phone || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Pet parent email</strong></div>
                                  <div className="col-sm-8">{cellData?.parentId?.email || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Lab type</strong></div>
                                  <div className="col-sm-8">{cellData?.type || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Test type</strong></div>
                                  <div className="col-sm-8">{cellData?.name || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Request date</strong></div>
                                  <div className="col-sm-8">{getDateDDMMYYYY(cellData?.createdAt) || "N/A"}</div>
                              </div>
                              <div className="row data-cell">
                                  <div className="col-sm-4 title"><strong>Notes</strong></div>
                                  <div className="col-sm-8">{cellData?.notes || "N/A"}</div>
                              </div>
                          </div>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      </div>
                  </div>
              </div>
          </div>
      </React.Fragment>
  )
}

export default LabRequestViewBody