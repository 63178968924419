// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog {
  max-width: 500px;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 1rem 1.5rem;
}

.modal-title {
  font-weight: 600;
  color: #333;
}

.modal-body {
  padding: 1.5rem;
}
.modal-body .data-box .data-cell .title {
  color: #e75c25;
}

.modal-body .row {
  margin-bottom: 0.75rem;
}

.modal-body .row:last-child {
  margin-bottom: 0;
}

.modal-body .col-sm-4 {
  color: #6c757d;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 1rem 1.5rem;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}`, "",{"version":3,"sources":["webpack://./src/pages/Labs/labs.scss"],"names":[],"mappings":"AAAE;EACE,gBAAA;AACJ;;AACE;EACE,kBAAA;EACA,wCAAA;AAEJ;;AAAE;EACE,gCAAA;EACA,oBAAA;AAGJ;;AADE;EACE,gBAAA;EACA,WAAA;AAIJ;;AAFE;EACE,eAAA;AAKJ;AADY;EACI,cAAA;AAGhB;;AAEE;EACE,sBAAA;AACJ;;AACE;EACE,gBAAA;AAEJ;;AAAE;EACE,cAAA;AAGJ;;AADE;EACE,6BAAA;EACA,oBAAA;AAIJ;;AAFE;EACE,yBAAA;EACA,qBAAA;AAKJ;;AAHE;EACE,yBAAA;EACA,qBAAA;AAMJ","sourcesContent":["  .modal-dialog {\n    max-width: 500px;\n  }\n  .modal-content {\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  .modal-header {\n    border-bottom: 1px solid #dee2e6;\n    padding: 1rem 1.5rem;\n  }\n  .modal-title {\n    font-weight: 600;\n    color: #333;\n  }\n  .modal-body {\n    padding: 1.5rem;\n\n    .data-box {\n        .data-cell {\n            .title {\n                color: #e75c25\n            }\n        }\n    }\n  }\n  .modal-body .row {\n    margin-bottom: 0.75rem;\n  }\n  .modal-body .row:last-child {\n    margin-bottom: 0;\n  }\n  .modal-body .col-sm-4 {\n    color: #6c757d;\n  }\n  .modal-footer {\n    border-top: 1px solid #dee2e6;\n    padding: 1rem 1.5rem;\n  }\n  .btn-secondary {\n    background-color: #6c757d;\n    border-color: #6c757d;\n  }\n  .btn-secondary:hover {\n    background-color: #5a6268;\n    border-color: #545b62;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
