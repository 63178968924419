// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  flex-wrap: wrap;
}

.sliding-options {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #672b88;
  color: #fff;
  height: 100%;
  transform: translateX(100%); /* Start off-screen to the right */
  transition: transform 0.5s ease-out; /* Smooth slide-in transition */
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.sliding-options .option {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
}
.sliding-options .option:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.sliding-options.slide-in {
  transform: translateX(0); /* Move into view */
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/dashboard.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA,EAAA,kCAAA;EACA,mCAAA,EAAA,+BAAA;EACA,6BAAA;EACA,2BAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,6BAAA;EACA,kBAAA;AACR;AAAQ;EACI,oCAAA;AAEZ;;AAGE;EACE,wBAAA,EAAA,mBAAA;AAAJ","sourcesContent":[".legend {\n    flex-wrap: wrap;\n}\n\n.sliding-options { \n    position: absolute;\n    top: 0; \n    right: 0;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    background-color: #672b88;\n    color: #fff;\n    height: 100%;\n    transform: translateX(100%); /* Start off-screen to the right */\n    transition: transform 0.5s ease-out; /* Smooth slide-in transition */\n    border: 1px solid transparent;\n    border-top-left-radius: 5px;\n    border-bottom-left-radius: 5px;\n    padding-left: 10px;\n    padding-right: 10px;\n\n    .option {\n        padding: 5px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 100%; \n        border: 1px solid transparent;\n        border-radius: 5px;\n        &:hover{\n            background-color: rgba(0,0,0,0.5);\n        }\n    }\n  }\n  \n  .sliding-options.slide-in {\n    transform: translateX(0); /* Move into view */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
