import React from "react"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // This lifecycle method will be called if an error is thrown during rendering
  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true }
  }

  // You can also log the error to an error reporting service here
  componentDidCatch(error, errorInfo) {
    console.log("Error caught by ErrorBoundary:", error, errorInfo)
    // You could also send this to a logging service if desired
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI for rendering in case of an error
      return <h1>Something went wrong.</h1>
    }

    // If no error, render children as usual
    return this.props.children
  }
}

export default ErrorBoundary
