import { createSlice } from "@reduxjs/toolkit"
import { controllers } from "chart.js"
import { getUserIdFromStorage } from "helpers/utils"
import {
  getParent,
  getGeneralPatients,
  getLoggedInUserDetails,
  fetchAppointments,
  getServiceType,
  getPetTypes,
  fetchPets,
  getDiagnosisList,
  getMedicineList,
  fetchAppointmentsBasedOnDoctorId,
  fetchTimings,
  //pricing Categories
  getPricingCategoryByVetId,
  //Pricing
  getAllPricingByVetIdAndCategoryId,
  //Generic pricing
  getGenericCategoryByVetId,
  //Inventory items
  getInventoryitemsByVetId,
  getAllParentsByVetId,
  getinventoryItemById,
  //Manufacturers
  getManufacturersByVetId,
  //Aisles
  getAislesByVetId,
  //Inventory Type
  getInventoryTypeByVetId,
  //Weekly Revenue
  fetchWeeklyRevenueByVetId,
  //Weekly patient Count
  fetchWeeklyPatientCountByVetId,
  //Yearly Revenue By Month
  fetchYearlyRevenueByVetId,
  getVetTimingsByVetId,
  fetchDashboardApi,
  fetchDashboardApiTwo,
} from "../../helpers/api_helper"

const { actions, reducer } = createSlice({
  name: "patientSlice",
  initialState: {
    authDetails: JSON.parse(localStorage.getItem("authUser")),
    parentList: [],
    generalPatients: [],
    appointments: [],
    appointmentsOfDoc: [],
    serviceType: [],
    petTypes: [],
    pets: [],
    diagnosisList: [],
    medicineList: [],
    isLoading: false,
    timings: [],
    //Pricing
    pricingList: [],
    //pricingCategory
    pricingCategoryList: [],
    //generic Category
    genericCategoryList: [],
    //Inventory Items
    inventoryitemList: [],
    //Manufacturers
    manufacturerList: [],
    //Aisles
    aisleList: [],
    //Inventory Type
    inventoryTypeList: [],
    //Weekly Revenue
    weeklyRevenueList: [],
    //Weekly Patient Count
    weeklyPatientCountList: [],
    //Yearly Revenue By Month
    yearlyRevenueList: [],
    vetTimings: [],
    dashboardDetails: {}
  },
  reducers: {
    setParents: (state, { payload }) => {
      state.parentList = payload
    },
    setGeneralPatients: (state, { payload }) => {
      state.generalPatients = payload
    },
    setProfileData: (state, { payload }) => {
      state.profileData = payload.profileData
      state.security = payload.security
      state.ownershipTransfer = payload.ownershipTransfer
      state.emailSubscription = payload.emailSubscription
      state.meta = payload.meta
    },
    setAuthUtils: (state, { payload }) => {
      state.authDetails = payload
    },
    setAppointments: (state, { payload }) => {
      state.appointments = payload
    },
    //pricing reducer
    setPricing: (state, { payload }) => {
      state.pricingList = payload
    },
    setServiceType: (state, { payload }) => {
      state.serviceType = payload
    },
    setPetTypes: (state, { payload }) => {
      state.petTypes = payload
    },
    setGlobalLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setPets: (state, { payload }) => {
      state.pets = payload
    },
    setDiagnosisList: (state, { payload }) => {
      state.diagnosisList = payload
    },
    setMedicineList: (state, { payload }) => {
      state.medicineList = payload
    },
    setAppointmentsOfDoctor: (state, { payload }) => {
      state.appointmentsOfDoc = payload
    },
    setDocTimings: (state, { payload }) => {
      state.timings = payload
    },
    setPricingCategory: (state, { payload }) => {
      state.pricingCategoryList = payload
    },
    setGenericCategory: (state, { payload }) => {
      state.genericCategoryList = payload
    },
    //Invetory items Reducer
    setinventoryitems: (state, { payload }) => {
      state.inventoryitemList = payload
    },
    //Manufacturer Reducer
    setManufacturers: (state, { payload }) => {
      state.manufacturerList = payload
    },
    //Manufacturer Reducer
    setAisles: (state, { payload }) => {
      state.aisleList = payload
    },
    setInventoryTypes: (state, { payload }) => {
      state.inventoryTypeList = payload
    },
    //Weekly Revenue
    setWeeklyRevenue: (state, { payload }) => {
      state.weeklyRevenueList = payload
    },
    //Weekly patient Count
    setWeeklyPatientCount: (state, { payload }) => {
      state.weeklyPatientCountList = payload
    },
    //Yearly Revenue By Month
    setYearlyRevenueCount: (state, { payload }) => {
      state.yearlyRevenueList = payload
    },
    setvetTimings: (state, { payload }) => {
      state.vetTimings = payload
    },

    setDashboardDetails: (state, { payload }) => {
      state.dashboardDetails = {...state.dashboardDetails, ...payload}
    },
  },
})

export default reducer

export const {
  setStatus,
  setGeneralPatients,
  setAuthUtils,
  setAppointments,
  setPricing,
  setServiceType,
  setPetTypes,
  setPets,
  setParents,
  setDiagnosisList,
  setMedicineList,
  setAppointmentsOfDoctor,
  setGlobalLoading,
  setDocTimings,
  setPricingCategory,
  setGenericCategory,
  setinventoryitems,
  setManufacturers,
  setAisles,
  setInventoryTypes,
  setWeeklyRevenue,
  setWeeklyPatientCount,
  setYearlyRevenueCount,
  setvetTimings,
  setDashboardDetails
} = actions

export const getParentList = data => async dispatch => {
  try {
    const response = await getAllParentsByVetId(data)
    if (response.status === 1) {
      dispatch(setParents(response.data))
    }
    return response.data
  } catch (error) {
    return false
  }
}
export const getPets = () => async dispatch => {
  try {
    const response = await fetchPets()
    if (response.status == 1) {
      dispatch(setPets(response.data))
    }
    return response.data
  } catch (error) {
    return false
  }
}

export const getGeneralPatientsList = vetId => async dispatch => {
  try {
    const response = await getGeneralPatients(vetId)

    if (response.status == 1) {
      dispatch(setGeneralPatients(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

export const getDetails = id => async (dispatch, getState) => {
  try {
    const state = getState()

    const response = await getLoggedInUserDetails({
      userId: id || getUserDetails(state)._id || getUserIdFromStorage(),
    })
    if (response.status == 1) {
      dispatch(setAuthUtils(response.data))
      localStorage.setItem("authUser", JSON.stringify(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getTimingsByDocId = (id, timingObj) => async dispatch => {
  try {
    const response = await fetchTimings(id, timingObj)
    if (response.status == 1) {
      dispatch(setDocTimings(response.data))
    }
    return response.data
  } catch (error) {
    return false
  }
}

export const getTodaysAppointments = () => async dispatch => {
  try {
    const response = await fetchAppointments()

    if (response.status == 1) {
      dispatch(setAppointments(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

//pricing Action Method

export const getPricingDetails = id => async dispatch => {
  try {
    if (!id) {
      // Handle the case where id is not provided
      throw new Error("Invalid ID")
    }

    const response = await getAllPricingByVetIdAndCategoryId(id)

    if (response.status === 1) {
      dispatch(setPricing(response.data))
      return true
    } else {
      // Handle the case where the API call was not successful
      throw new Error("API call failed")
    }
  } catch (error) {
    // Handle the error and return false
    console.error("Error in getPricingDetails:", error)
    return false
  }
}

export const fetchAppointmentTypes = () => async dispatch => {
  try {
    const response = await getServiceType()

    if (response.status == 1) {
      dispatch(setServiceType(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

export const fetchPetTypes = () => async dispatch => {
  try {
    const response = await getPetTypes()

    if (response.status == 1) {
      dispatch(setPetTypes(response.data))
    }
    return response.data
  } catch (error) {
    return false
  }
}

export const fetchDiagnosisList = () => async dispatch => {
  try {
    const response = await getDiagnosisList()
    if (response.status == 1) {
      dispatch(setDiagnosisList(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const fetchMedicines = () => async dispatch => {
  try {
    const response = await getMedicineList()
    if (response.status == 1) {
      dispatch(setMedicineList(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getAppointmentsByDocId = (id, virtual = false )=> async dispatch => {
  try {
    const response = await fetchAppointmentsBasedOnDoctorId(id, virtual)
    if (response.status == 1) {
      dispatch(setAppointmentsOfDoctor(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

//pricing Category A Method

export const getPricingCategory = id => async dispatch => {
  console.log("id??index", id)
  try {
    const response = await getPricingCategoryByVetId(id)
    console.log("response????index", response.data)
    if (response.status == 1) {
      dispatch(setPricingCategory(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

//Generic Category Method

export const getGenericCategory = id => async dispatch => {
  try {
    const response = await getGenericCategoryByVetId(id)

    if (response.status == 1) {
      dispatch(setGenericCategory(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

//Inventory items Method

export const getInventoryItems = id => async dispatch => {
  try {
    const response = await getInventoryitemsByVetId(id)

    if (response.status == 1) {
      dispatch(setinventoryitems(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}

export const getManufacturers = id => async dispatch => {
  try {
    const response = await getManufacturersByVetId(id)

    if (response.status == 1) {
      dispatch(setManufacturers(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getAisles = id => async dispatch => {
  try {
    const response = await getAislesByVetId(id)

    if (response.status == 1) {
      dispatch(setAisles(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getInventoryTypes = id => async dispatch => {
  try {
    const response = await getInventoryTypeByVetId(id)

    if (response.status == 1) {
      dispatch(setInventoryTypes(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getWeeklyRevenue = id => async dispatch => {
  try {
    const response = await fetchWeeklyRevenueByVetId(id)

    if (response.status == 1) {
      dispatch(setWeeklyRevenue(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getWeeklyPatientCount = id => async dispatch => {
  try {
    const response = await fetchWeeklyPatientCountByVetId(id)

    if (response.status == 1) {
      dispatch(setWeeklyPatientCount(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getyearlyRevenueCount = id => async dispatch => {
  try {
    const response = await fetchYearlyRevenueByVetId(id)

    if (response.status == 1) {
      dispatch(setYearlyRevenueCount(response.data))
    }
    return true
  } catch (error) {
    return false
  }
}
export const getVetCurrentTimings = (id, isTimingLoading = () => {}) => async dispatch => {
  try {
    isTimingLoading(true);
    const response = await getVetTimingsByVetId(id);
    isTimingLoading(false);

    if (response.status == 1) {
      dispatch(setvetTimings(response.data?.timings))
    }
    return response.data
  } catch (error) {
    return false
  }
}

export const getDashboardApi = (params) => async dispatch => {
  try {
    dispatch(setGlobalLoading(true))
    const response = await fetchDashboardApi(params);
    if (response.status) {
      dispatch(setDashboardDetails(response.data))
    }
    dispatch(setGlobalLoading(false))
    return true
  } catch (error) {
    dispatch(setGlobalLoading(false))
    return false
  }
}

export const getDashboardApiTwo = (petType, vetId, startDate, endDate) => async dispatch => {
  try {
    dispatch(setGlobalLoading(true))
    const response = await fetchDashboardApiTwo(petType, vetId, startDate, endDate);
    if (response.status) {
      dispatch(setDashboardDetails(response.data.data))
    }
    dispatch(setGlobalLoading(false))
    return true
  } catch (error) {
    dispatch(setGlobalLoading(false))
    return false
  }
}

// Getters
export const getUserDetails = state => state.Patients.authDetails
export const getAppointments = state => state.Patients.appointments
export const getServices = state => state.Patients.serviceType
export const getTypes = state => state.Patients.petTypes
export const getAllPets = state => state.Patients.pets

export const getAllParents = state => state.Patients.parentList
export const getAllDiagnosis = state => state.Patients.diagnosisList
export const getAllMedicines = state => state.Patients.medicineList
export const getAppointmentsOfDoc = state => state.Patients.appointmentsOfDoc
export const getIsLoading = state => state.Patients.isLoading

export const getTimingDetails = state => state.Patients.timings

//Pricing getter function
export const getpricing = state => state.Patients.pricingList

//Pricing Category getter function

export const getCategoryOfPricing = state => state.Patients.pricingCategoryList

//Inventory item getter function

export const getInventoryitems = state => state.Patients.inventoryitemList

//Generic Category getter function

export const getCategoryOfGenericType = state =>
  state.Patients.genericCategoryList

//Manufacturers getter function

export const getAllManufacturers = state => state.Patients.manufacturerList

//Aisle getter function

export const getAllAisle = state => state.Patients.aisleList

//Inventory Type getter function

export const getAllInventoryType = state => state.Patients.inventoryTypeList

//Weekly Revenue getter function
export const getAllWeeklyRevenue = state => state.Patients.weeklyRevenueList

//Weekly Patient Count getter function
export const getAllWeeklyPatientCount = state =>
  state.Patients.weeklyPatientCountList

//Yearly Revenue Count getter function
export const getAllYearlyRevenue = state => state.Patients.yearlyRevenueList
export const getAllTimings = state => state.Patients.vetTimings
export const getDashboardData = state => state.Patients.dashboardDetails
