import ButtonMain from "components/Common/Button"
import PropTypes from "prop-types"
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import Lottie from "react-lottie"
import * as animationData from "../../assets/images/lottie/delete.json"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, isDeleting }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <Lottie options={defaultOptions} height={48} width={48} />
              <h2>Are you sure?</h2>
              <h4>{"You won't be able to revert this!"}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <ButtonMain
                type="button"
                // isLoading={isDeleting}
                className="btn btn-primary btn-secondary btn-med btn-lg ms-2"
                onClick={onCloseClick}
              >
                No, Keep
              </ButtonMain>

              <ButtonMain
                type="button"
                isLoading={isDeleting}
                className="btn btn-primary btn-lg ms-2"
                onClick={onDeleteClick}
                style={{ minWidth: "120px" }}
              >
                Yes, Delete
              </ButtonMain>
              {/* <button
                type="button"
                className="btn btn-primary btn-lg ms-2"
                onClick={onDeleteClick}
              >
                Yes, Delete
              </button> */}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeleteModal
