// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-events-container .rbc-event {
  border: 1px solid #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Appointments/appointment.scss"],"names":[],"mappings":"AACI;EACI,iCAAA;AAAR","sourcesContent":[".rbc-events-container {\n    .rbc-event {\n        border: 1px solid #fff !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
