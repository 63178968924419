import ButtonMain from "components/Common/Button"
import React from "react"
import { Col, Label, Row, UncontrolledTooltip } from "reactstrap"

const Lobby = ({
  handleSubmit,
  connecting,
  onUserName,
  getUserName,
  isFromSeperateWindow,
}) => {
  return (
    <form className="text-center pt-4" onSubmit={handleSubmit}>
      {!getUserName && <h4 className="pb-2">Connect with patient</h4>}

      {getUserName && (
        <Row>
          <Col lg={3} />
          <Col lg={6}>
            <br />
            <br />
            <br />
            <Label className="col-form-label text-white">Enter your name</Label>
            <br />
            <input
              type="text"
              id="subject"
              onChange={e => onUserName(e.target.value)}
              className="form-control"
              placeholder="Enter name"
            />
            <br />
            <br />
          </Col>
          <Col lg={3} />
        </Row>
      )}

      <UncontrolledTooltip placement="right" target="startVideo">
        Connect
      </UncontrolledTooltip>

      <ButtonMain
        isLoading={connecting}
        className="video-phone video-phone--connect"
        type="submit"
        onClick={handleSubmit}
        id="startVideo"
      >
        <i className="mdi mdi-phone-outline" />
      </ButtonMain>
    </form>
  )
}

export default Lobby

// import ButtonMain from "components/Common/Button"
// import React from "react"
// import { Col, Label, Row, UncontrolledTooltip } from "reactstrap"
// import "bootstrap/dist/css/bootstrap.min.css"

// const Lobby = ({
//   handleSubmit,
//   connecting,
//   onUserName,
//   getUserName,
//   isFromSeperateWindow,
// }) => {
//   return (
//     <form className="text-center pt-4" onSubmit={handleSubmit}>
//       {/* Title for Lobby */}
//       {!getUserName && <h4 className="pb-3">Connect with a Patient</h4>}

//       {/* Input for Username if getUserName is true */}
//       {getUserName && (
//         <Row className="justify-content-center">
//           <Col lg={6} md={8} sm={12}>
//             <Label className="col-form-label text-white">Enter Your Name</Label>
//             <input
//               type="text"
//               id="subject"
//               onChange={e => onUserName(e.target.value)}
//               className="form-control"
//               placeholder="Enter your name"
//               required
//               style={{ padding: "10px", margin: "20px 0", borderRadius: "5px" }}
//             />
//           </Col>
//         </Row>
//       )}

//       {/* Tooltip for the connect button */}
//       <UncontrolledTooltip placement="right" target="startVideo">
//         Connect
//       </UncontrolledTooltip>

//       {/* Connect Button */}
//       <ButtonMain
//         isLoading={connecting}
//         className="video-phone video-phone--connect"
//         type="submit"
//         id="startVideo"
//         style={{
//           marginTop: "20px",
//           padding: "10px 30px",
//           borderRadius: "50px",
//         }}
//       >
//         {connecting ? (
//           <span>Connecting...</span>
//         ) : (
//           <>
//             <i className="mdi mdi-phone-outline" /> Connect
//           </>
//         )}
//       </ButtonMain>
//     </form>
//   )
// }

// export default Lobby
