import { getApp, getApps, initializeApp } from "firebase/app"
import { getMessaging, getToken, isSupported } from "firebase/messaging"

// Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEZMPgD--s9pP9Pl7SW3c8RNef41f4LPI",
  authDomain: "pasupalan-37c32.firebaseapp.com",
  projectId: "pasupalan-37c32",
  storageBucket: "pasupalan-37c32.appspot.com",
  messagingSenderId: "257183161318",
  appId: "1:257183161318:web:0ccbc61c3b2408fb63d427",
  measurementId: "G-WJSXPGEXH7",
}

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp()

const messaging = async () => {
  const supported = await isSupported()
  return supported ? getMessaging(app) : null
}

export const requestFCMToken = async () => {
  try {
    const fcmMessaging = await messaging()
    if (fcmMessaging) {
      const token = await getToken(fcmMessaging, {
        vapidKey:
          "BC7rNp7tfra6GmDoc1oRoDI3NHz0gPQZ85YcxsaY0JMyGpBHM9L85BPII1Li_oGOMnbZ-2EQiUQmQ42Qat7qEbk",
      })
      return token
    }
    return null
  } catch (err) {
    console.error("An error occurred while fetching the token:", err)
    return null
  }
}

export { app, messaging }
